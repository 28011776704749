import React, { useEffect, useState } from 'react';

import {reservationTypes} from '../constants/roomAttributes';
import {fetch} from '../utils/httpUtil.js';
import {checkError, logError} from '../utils/helpers.js';
import {Alert} from 'reactstrap';

import { useNavigate } from "react-router-dom";

function CheckOuts() {

    const navigate = useNavigate();

    const [checkOutItems, setCheckOutItems] = useState([]);   

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    function showReservationDetails(rTypeID, rID, gID, guestName) {

      const reservationItem = {
        rTypeId: rTypeID, 
        rStatusId : 4, //Checked In
        rId: rID, 
        gId: gID, 
        guestName: guestName
      }

      navigate("/reservationContainer", { state: { reservationItem } });
    }

    useEffect(() => {
      fetchCheckOutDetails();
    }, []);


    function fetchCheckOutDetails() {     
      fetch("apiCheckouts")
        .then((response) => {
           return checkError(response);
        })
        .then((result) => {
            setCheckOutItems(result); 
          })
        .catch((error) => {
          setAlertMsg("Oops! Something went wrong! Please try again!")
          setAlertColor("danger");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000); 
          logError(error);
        });
    }

    const parseText = (text) => {
      const blocks = text.split(/(?=[A-Za-z ]+:\s)/); // Splits text by block names
      return blocks.map(block => {
        const [blockName, rooms] = block.split(': ');
        return {
          blockName: blockName.trim(),
          rooms: rooms.trim()
        };
      });
    };

      
    if (checkOutItems.length === 0) {
      return (
        <>
          <div className="divSectionHeader"><h4>Open Check Outs</h4>
          <hr />  
          No checkouts!
          </div>  
        </>
        );
  }
  else {
      return (
        <>
            <div className="divSectionHeader"><h4>Open Check Outs</h4>
                <hr />
                <Alert color={alertColor} isOpen={showAlert}>
                  {alertMsg}
                </Alert>  
                <div className="divList">
                  <ol>
                          {checkOutItems.map(item => {       
                                                 
                            const typeClass = reservationTypes[item.reservation_type_id].toLowerCase();
                            return (
                              <li>
                                <span className="spGuestItem" onClick={() => showReservationDetails(item.reservation_type_id, item.reservation_id, item.guest_id, item.guest_name)}>       
                                  <span className={`reservation-item ${typeClass}`}>
                                    {reservationTypes[item.reservation_type_id]}  
                                  </span>
                                  {' - '}
                                  <b style={{color:'#8b4513'}}>{item.guest_name}</b>
                                  {item.referred_by == null ? '' : ` c/o `}<b className="bRef" style={{color:'green'}}>{item.referred_by}</b>       
                                </span> 
                                {item.is_delayed === 1 ? <span className="spDelayed" style={{color:'red', paddingLeft:'1em', fontVariant:'petite-caps', fontWeight:'bold'}}>(Overdue)</span> : ''}                                 
                                <div className="block-rooms" dangerouslySetInnerHTML={{ __html: item.block_rooms }}></div>
                              </li>

                            );                               
                          })}     
                    </ol>      
              </div>
          </div>
        </>
            );
            
    }
}

export default CheckOuts;