import axios from 'axios';

import {API_URL} from '../config/config';

export function fetch(datapoint) {
    return axios
        .get(API_URL + datapoint, {
            headers: {'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('accessToken')}
        });
}

export function store(datapoint, data) {
    return axios
        .post(API_URL + datapoint, data, {
            headers: 
            {
                'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
}

export function authenticateUser(baseURL, data) {  
    return axios
        .post(baseURL, data, {
            headers: 
            {
                'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('accessToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });
}

export function destroy(datapoint) {
    return axios
        .delete(API_URL + datapoint, {
            headers: {'Authorization': 'Bearer' + ' ' + sessionStorage.getItem('accessToken')}
        });
}