import React, { useRef, useState, useEffect } from 'react';
import { fetch, store } from '../utils/httpUtil';
import { logError, checkError } from '../utils/helpers';
import GuestDetailsForm from './GuestDetailsForm';
import { Alert, Form } from "reactstrap";
import "../css/form.css";
import "../css/react-confirm-alert.css";

function GuestDetails({ gId, onClose }) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const [guestDetails, setGuestDetails] = useState({
        guestId: gId,
        referenceId: null,
        idNumber: null,
        firstName: null,
        lastName: null,
        emailId: null,
        phoneNo: null,
        address: null,
        city: null,
        zipCode: null,
        state: null,
        countryId: null,
        ecGuestId: null,
        ecFirstName: null,
        ecLastName: null,
        ecPhoneNo: null,
        ecRelationship: null
    });

    const onSubmit = (e) => {
        e.preventDefault();
        saveGuestDetails(e);    
    };

    function saveGuestDetails(e) {
        if (guestDetails.countryId === 0 || guestDetails.countryId === null){
            setAlertMsg("Please select a country!");    
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return;
        }

        updateGuestDetails();
    }

    function updateGuestDetails() { 

        const payload = {   
            reference_id: guestDetails.referenceId, 
            id_number: guestDetails.idNumber,   
            first_name: guestDetails.firstName,
            last_name: guestDetails.lastName,
            email_id: guestDetails.emailId,
            phone_no: guestDetails.phoneNo,
            address: guestDetails.address,
            city: guestDetails.city,
            zip_code: guestDetails.zipCode,
            state: guestDetails.state,
            country_id: guestDetails.countryId,
            guest_emergency_contact_id: guestDetails.ecGuestId,
            e_first_name: guestDetails.ecFirstName,
            e_last_name: guestDetails.ecLastName,
            e_phone_no: guestDetails.ecPhoneNo,
            e_relationship: guestDetails.ecRelationship,
            user_id: sessionStorage.getItem('userId')   
        }; 

        console.log(payload);       

        store("apiGuests/" + guestDetails.guestId, JSON.stringify(payload))
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {   
                setAlertMsg("Guest details updated successfully.")
                setAlertColor("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    onClose();
                }, 2000); 
            })
            .catch((error) => {
                setAlertMsg("Oops! Something went wrong! Please try again!")
                setAlertColor("danger");
                setShowAlert(true);
                setTimeout(() => {
                setShowAlert(false);
                }, 2000); 
                logError(error);
            });
    }

    return (
        <>


            <Form onSubmit={onSubmit}>
                    <div className="save-button-container" style={{marginTop:'-2em'}}>
                        <button className="btnBig" type="submit" style={{marginRight:'-1em'}}>Save</button>
                    </div>  
            </Form> 
            <Alert color={alertColor} isOpen={showAlert}>
                {alertMsg}
            </Alert>

            <GuestDetailsForm
                guestDetails={guestDetails}
                setGuestDetails={setGuestDetails}
                onSubmit={onSubmit}/>

        </>
    );
}

export default GuestDetails;
