export const countries = {
1: "Afghanistan",
2: "Åland Islands",
3: "Albania",
4: "Algeria",
5: "American Samoa",
6: "Andorra",
7: "Angola",
8: "Anguilla",
9: "Antarctica",
10: "Antigua and Barbuda",
11: "Argentina",
12: "Armenia",
13: "Aruba",
14: "Australia",
15: "Austria",
16: "Azerbaijan",
17: "Bahamas",
18: "Bahrain",
19: "Bangladesh",
20: "Barbados",
21: "Belarus",
22: "Belgium",
23: "Belize",
24: "Benin",
25: "Bermuda",
26: "Bhutan",
27: "Bolivia",
28: "Bonaire, Sint Eustatius and Saba",
29: "Bosnia and Herzegovina",
30: "Botswana",
31: "Bouvet Island",
32: "Brazil",
33: "British Indian Ocean Territory",
34: "Brunei Darrussalam",
35: "Bulgaria",
36: "Burkina Faso",
37: "Burundi",
38: "Cambodia",
39: "Cameroon",
40: "Canada",
41: "Cape Verde",
42: "Cayman Islands",
43: "Central African Republic",
44: "Chad",
45: "Chile",
46: "China",
47: "Christmas Island",
48: "Cocos Islands",
49: "Colombia",
50: "Comoros",
51: "Congo, Democratic Republic of the",
52: "Congo, Republic of the",
53: "Cook Islands",
54: "Costa Rica",
55: "Côte d'Ivoire",
56: "Croatia",
57: "Cuba",
58: "Curaçao",
59: "Cyprus",
60: "Czech Republic",
61: "Denmark",
62: "Djibouti",
63: "Dominica",
64: "Dominican Republic",
65: "Ecuador",
66: "Egypt",
67: "El Salvador",
68: "Equatorial Guinea",
69: "Eritrea",
70: "Estonia",
71: "Eswatini (Swaziland)",
72: "Ethiopia",
73: "Falkland Islands",
74: "Faroe Islands",
75: "Fiji",
76: "Finland",
77: "France",
78: "French Guiana",
79: "French Polynesia",
80: "French Southern Territories",
81: "Gabon",
82: "Gambia",
83: "Georgia",
84: "Germany",
85: "Ghana",
86: "Gibraltar",
87: "Greece",
88: "Greenland",
89: "Grenada",
90: "Guadeloupe",
91: "Guam",
92: "Guatemala",
93: "Guernsey",
94: "Guinea",
95: "Guinea-Bissau",
96: "Guyana",
97: "Haiti",
98: "Heard and McDonald Islands",
99: "Holy See",
100: "Honduras",
101: "Hong Kong",
102: "Hungary",
103: "Iceland",
104: "India",
105: "Indonesia",
106: "Iran",
107: "Iraq",
108: "Ireland",
109: "Isle of Man",
110: "Israel",
111: "Italy",
112: "Jamaica",
113: "Japan",
114: "Jersey",
115: "Jordan",
116: "Kazakhstan",
117: "Kenya",
118: "Kiribati",
119: "Kuwait",
120: "Kyrgyzstan",
121: "Lao People's Democratic Republic",
122: "Latvia",
123: "Lebanon",
124: "Lesotho",
125: "Liberia",
126: "Libya",
127: "Liechtenstein",
128: "Lithuania",
129: "Luxembourg",
130: "Macau",
131: "Macedonia",
132: "Madagascar",
133: "Malawi",
134: "Malaysia",
135: "Maldives",
136: "Mali",
137: "Malta",
138: "Marshall Islands",
139: "Martinique",
140: "Mauritania",
141: "Mauritius",
142: "Mayotte",
143: "Mexico",
144: "Micronesia",
145: "Moldova",
146: "Monaco",
147: "Mongolia",
148: "Montenegro",
149: "Montserrat",
150: "Morocco",
151: "Mozambique",
152: "Myanmar",
153: "Namibia",
154: "Nauru",
155: "Nepal",
156: "Netherlands",
157: "New Caledonia",
158: "New Zealand",
159: "Nicaragua",
160: "Niger",
161: "Nigeria",
162: "Niue",
163: "Norfolk Island",
164: "North Korea",
165: "Northern Mariana Islands",
166: "Norway",
167: "Oman",
168: "Pakistan",
169: "Palau",
170: "Palestine, State of",
171: "Panama",
172: "Papua New Guinea",
173: "Paraguay",
174: "Peru",
175: "Philippines",
176: "Pitcairn",
177: "Poland",
178: "Portugal",
179: "Puerto Rico",
180: "Qatar",
181: "Réunion",
182: "Romania",
183: "Russia",
184: "Rwanda",
185: "Saint Barthélemy",
186: "Saint Helena",
187: "Saint Kitts and Nevis",
188: "Saint Lucia",
189: "Saint Martin",
190: "Saint Pierre and Miquelon",
191: "Saint Vincent and the Grenadines",
192: "Samoa",
193: "San Marino",
194: "Sao Tome and Principe",
195: "Saudi Arabia",
196: "Senegal",
197: "Serbia",
198: "Seychelles",
199: "Sierra Leone",
200: "Singapore",
201: "Sint Maarten",
202: "Slovakia",
203: "Slovenia",
204: "Solomon Islands",
205: "Somalia",
206: "South Africa",
207: "South Georgia",
208: "South Korea",
209: "South Sudan",
210: "Spain",
211: "Sri Lanka",
212: "Sudan",
213: "Suriname",
214: "Svalbard and Jan Mayen Islands",
215: "Sweden",
216: "Switzerland",
217: "Syria",
218: "Taiwan",
219: "Tajikistan",
220: "Tanzania",
221: "Thailand",
222: "Timor-Leste",
223: "Togo",
224: "Tokelau",
225: "Tonga",
226: "Trinidad and Tobago",
227: "Tunisia",
228: "Turkey",
229: "Turkmenistan",
230: "Turks and Caicos Islands",
231: "Tuvalu",
232: "Uganda",
233: "Ukraine",
234: "United Arab Emirates",
235: "United Kingdom",
236: "United States",
237: "Uruguay",
238: "US Minor Outlying Islands",
239: "Uzbekistan",
240: "Vanuatu",
241: "Venezuela",
242: "Vietnam",
243: "Virgin Islands, British",
244: "Virgin Islands, U.S.",
245: "Wallis and Futuna",
246: "Western Sahara",
247: "Yemen",
248: "Zambia",
249: "Zimbabwe"
}

export default countries;
	