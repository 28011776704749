import {React, useState, useEffect} from "react";

import { fetch, store, destroy } from '../utils/httpUtil.js';
import { logError, checkError } from '../utils/helpers';
import { blocks, floors, roomStatuses} from '../constants/roomAttributes.js';

import moment from 'moment';  

import "../css/allocate-rooms.css";

import {Alert} from 'reactstrap';

import { Link } from 'react-router-dom';     

import AllocateRooms from './AllocateRooms';
import Donations from './Donations';    

import { confirmAlert } from 'react-confirm-alert'; 
import {Suspense} from 'react'; 

import { mkConfig, generateCsv, download } from "export-to-csv";

function RoomBookings({ reservationDetail, refreshReservation, receivedDonationRef, updateDates, isCancelled }) {

    const [refresh, setRefresh] = useState(false);      

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const [bedsCount, setBedsCount] = useState(0);    
    const [totalAmount, setTotalAmount] = useState(0); 

    const [roomBookings, setRoomBookings] = useState({
        guestId: reservationDetail.guestId, 
        reservationId: reservationDetail.reservationId,
        arrivalDate: moment(reservationDetail.arrivalDate).format('YYYY-MM-DD'),
        departureDate: reservationDetail.departureDate,
        reservationStatusId: sessionStorage.getItem('reservationStatusId') === null ? reservationDetail.rStatusId : sessionStorage.getItem('reservationStatusId'),  
        roomBookingItems: []
    });
    

    const statusClasses = {
        1: 'autoassigned',
        2: 'allocated',
        3: 'checkedin',
        4: 'checkedout',
        5: 'housekeepingcompleted',
        6: 'cancelled',
        7: 'noshow'
    };

    useEffect(() => {    
        sessionStorage.setItem('reservationStatusId', roomBookings.reservationStatusId); 
        fetchRoomsBookings();
    }, []);

    useEffect(() => {    
        fetchRoomsBookings();
    }, [roomBookings.reservationStatusId]);

    useEffect(() => {    
        if (isCancelled) {
            fetchRoomsBookings();
        }
    }, [isCancelled]);

    useEffect(() => {
        if (refresh) { 
            fetchRoomsBookings();
          setRefresh(false); // Reset the refresh state
        }
    }, [refresh]);
  
    function fetchRoomsBookings() {

        fetch("apiRoomBookings/" + roomBookings.reservationId + "?type=2")
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {    
            
            const arrSearchItems = result.map(item => ({
                room_booking_id: item.room_booking_id,  
                room_id: item.room_id,
                room_no: item.room_no,
                floor_no: item.floor_no,
                total_beds: item.total_beds,
                room_category_id: item.room_category_id,
                room_rent: item.room_rent,
                block_id: item.block_id,
                has_AC: item.has_AC,
                has_cooler: item.has_cooler,    
                date_of_arrival: item.date_of_arrival,
                date_of_departure: item.date_of_departure,
                next_arrival_date: item.next_arrival_date,
                room_status_id: item.room_status_id 
            }));

            setRoomBookings({ ...roomBookings, roomBookingItems: arrSearchItems });
        
            const newBedsCount = arrSearchItems.reduce((acc, room) => acc + room.total_beds, 0); 

            const newTotalAmount = arrSearchItems.reduce((acc, room) => {
                const arrivalDate = moment(room.date_of_arrival);
                const departureDate = moment(room.date_of_departure);
                const daysCount = departureDate.diff(arrivalDate, 'days');
                const roomRent = room.block_id === 13 ? room.room_rent : room.room_rent * daysCount;
                return acc + roomRent;
            }, 0);
            
    
            setBedsCount(newBedsCount);
            setTotalAmount(newTotalAmount); 

            document.getElementById("spExpectedDonation").innerHTML = newTotalAmount.toLocaleString('en-IN');
            document.getElementById("spGrandBeds").innerHTML = newBedsCount;

        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
            logError(constructor.name + " " + error);
        });
    }

    function removeRoom(roomBookingId) {
        destroy("apiRoombookings/" + roomBookingId + "?uId=" + sessionStorage.getItem('userId'))
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {          
                setAlertMsg("Room removed from the reservation successfully!");
                setAlertColor("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    refreshReservation(result[0].reservation_status_id);
                }, 2000);
    
                setRoomBookings(prevState => {
                    const updatedRoomBookingItems = prevState.roomBookingItems.filter(item => item.room_booking_id !== roomBookingId);
    
                    const newBedsCount = updatedRoomBookingItems.reduce((acc, room) => acc + room.total_beds, 0);
                    
                    const newTotalAmount = updatedRoomBookingItems.reduce((acc, room) => {
                        const arrivalDate = moment(room.date_of_arrival);
                        const departureDate = moment(room.date_of_departure);
                        const daysCount = departureDate.diff(arrivalDate, 'days');
                        return acc + (room.room_rent * daysCount);
                    }, 0);
   
    
                    // Update the state
                    setBedsCount(newBedsCount); 
                    document.getElementById("spGrandBeds").innerHTML = newBedsCount;
                    setTotalAmount(newTotalAmount);
    
                    return {
                        ...prevState,
                        roomBookingItems: updatedRoomBookingItems
                    };
                });
            })
            .catch((error) => {
                this.setState({
                    isLoaded: false,
                    error
                });
                logError(error);
            });
    }
    
    const handleArrivalDateChange = (roomBookingId, newArrivalDate) => {

        setRoomBookings(prevState => {
            const updatedRoomBookingItems = prevState.roomBookingItems.map(item => {
                if (item.room_booking_id === roomBookingId) {
                    return {
                        ...item,
                        date_of_arrival: newArrivalDate
                    };
                }
                return item;
            });

            const newTotalAmount = updatedRoomBookingItems.reduce((acc, room) => {
                const arrivalDate = moment(room.date_of_arrival);
                const departureDate = moment(room.date_of_departure);
                const daysCount = departureDate.diff(arrivalDate, 'days');
                return acc + (room.room_rent * daysCount);
            }, 0);

            setTotalAmount(newTotalAmount);

            const payload = {
                room_booking_id: roomBookingId,
                date_of_arrival: moment(newArrivalDate).format('YYYY-MM-DD'),   
                reservation_id: roomBookings.reservationId,
                user_id: sessionStorage.getItem('userId')
            };

            if (updatedRoomBookingItems) {
                store("apiRoombookings/", JSON.stringify(payload))    
                    .then((response) => {
                        return checkError(response);
                    })            
                    .then(result => {
                        setAlertMsg("Room arrival date updated successfully!");
                        setAlertColor("success");
                        setShowAlert(true);
                        updateDates(result[0].date_of_arrival, result[0].date_of_departure); //update the reservation dates  
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 2000);
    
                        //fetchRoomsBookings();
                    })
                    .catch(error => {
                        logError(error);
                    });
            }

            return { ...prevState, roomBookingItems: updatedRoomBookingItems };
        });
    };

    const handleDepartureDateChange = (roomBookingId, newDepartureDate) => {

        setRoomBookings(prevState => {
            const updatedRoomBookingItems = prevState.roomBookingItems.map(item => {
                if (item.room_booking_id === roomBookingId) {
                    return {
                        ...item,
                        date_of_departure: newDepartureDate
                    };
                }
                return item;
            });

            const newTotalAmount = updatedRoomBookingItems.reduce((acc, room) => {
                const arrivalDate = moment(room.date_of_arrival);
                const departureDate = moment(room.date_of_departure);
                const daysCount = departureDate.diff(arrivalDate, 'days');
                return acc + (room.room_rent * daysCount);
            }, 0);

            setTotalAmount(newTotalAmount);

            const payload = {
                room_booking_id: roomBookingId,
                date_of_departure: moment(newDepartureDate).format('YYYY-MM-DD'),
                reservation_id: roomBookings.reservationId,
                user_id: sessionStorage.getItem('userId')
            };

            if (updatedRoomBookingItems) {
                store("apiRoombookings/", JSON.stringify(payload))      
                    .then((response) => {
                        return checkError(response);
                    })          
                    .then(result => {
                        setAlertMsg("Room departure date updated successfully!");
                        setAlertColor("success");
                        setShowAlert(true);
                        updateDates(moment(result[0].date_of_arrival).format('YYYY-MM-DD'), moment(result[0].date_of_departure).format('YYYY-MM-DD')); //update the reservation dates 
                        setTimeout(() => {
                            setShowAlert(false);
                        }, 2000);
    
                        //fetchRoomsBookings();
                    })
                    .catch(error => {
                        logError(error);
                    });
            }

            return { ...prevState, roomBookingItems: updatedRoomBookingItems };
        });
    };

    function addMoreRooms() {   

        const reservationDetails = {    
            reservationId: roomBookings.reservationId,
            arrivalDate: roomBookings.arrivalDate,
            departureDate: roomBookings.departureDate,
        };

        confirmAlert({
            customUI: ({ onClose }) => {


              return (
                <div className="room-details">
                    <h3>Add Additional Rooms</h3>  
                    <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>  
                    <Suspense fallback={<div id="loader" class="loaderCenter"></div> }>           
                    <AllocateRooms reservationDetail={reservationDetails} refreshReservation={(rStatusId) => {refreshReservation(rStatusId); setRefresh(true); onClose();}}></AllocateRooms>
                    </Suspense>
                </div>
              )}
          })
    }

    function createRoomsString(selectedRooms){
        //loop through selected rooms and create a | separated string to pass to POST
        var str_rooms = "";
        for (var i =0; i < selectedRooms.length; i++)
        {  
        str_rooms+= selectedRooms[i] + "|";
        }
        str_rooms = str_rooms.substring(0,str_rooms.length-1);
        return str_rooms; 
    } 

    function checkInRooms() {   

        var isFullReservation = false;  
        var strRoomBookingIds = "";

        if (document.getElementById("chkAllRoomsSelected").checked === false) { 

            const chkAllRooms = document.getElementsByClassName("chkAllRooms"); 
            const chkAllRoomsCount = chkAllRooms.length;

            let selectedRooms = [];
            for (let i = 0; i < chkAllRoomsCount; i++) {
                if (chkAllRooms[i].checked) {
                    selectedRooms.push(chkAllRooms[i].id);
                }
            }

            if (selectedRooms.length === 0) {
                setAlertMsg("Please select at least one room to check in!");
                setAlertColor("info");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                return;
            } else {
                strRoomBookingIds = createRoomsString(selectedRooms);  
            }
        } else {
            isFullReservation = true;
        }
            
        const roomBookingsData = {
            r_id: isFullReservation === true ? roomBookings.reservationId : null, 
            str_room_booking_ids: isFullReservation === true ? null : strRoomBookingIds,
            user_id: sessionStorage.getItem('userId')   
        };


        store("apiCheckins/", JSON.stringify(roomBookingsData))
        .then((response) => {
            return checkError(response);
        })
        .then((result) => { 
            setAlertMsg("Rooms checked in successfully!");
            setAlertColor("success");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                refreshReservation(result[0].reservation_status_id); //update the reservation status to checked in 
            }, 2000);
            fetchRoomsBookings();
        })
        .catch((error) => {
            logError(error);
        });
    }

    function checkOutRooms() {
        var isFullReservation = false;  
        var strRoomBookingIds = "";
    
        if (document.getElementById("chkAllRoomsSelected").checked === false) { 
            const chkAllRooms = document.getElementsByClassName("chkAllRooms"); 
            const chkAllRoomsCount = chkAllRooms.length;
    
            let selectedRooms = [];
            for (let i = 0; i < chkAllRoomsCount; i++) {
                if (chkAllRooms[i].checked) {
                    selectedRooms.push(parseInt(chkAllRooms[i].id));
                }
            }
    
            if (selectedRooms.length === 0) {
                setAlertMsg("Please select at least one room to check out!");
                setAlertColor("info");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
                return;
            } else {
                strRoomBookingIds = createRoomsString(selectedRooms);  
            }
    
            const remainingRooms = roomBookings.roomBookingItems.filter(item => item.room_status_id !== 4 && !selectedRooms.includes(item.room_booking_id));   
            if (remainingRooms.length === 0) {
                donationMisMatch(isFullReservation, strRoomBookingIds);
                return;
            } else {
                saveCheckOut(isFullReservation, strRoomBookingIds); 
            }
        } else {
            isFullReservation = true;
            donationMisMatch(isFullReservation, strRoomBookingIds);
            return;
        }

    }

    function donationMisMatch(isFullReservation, strRoomBookingIds){
        const expectedDonation = document.getElementById("spExpectedDonation").innerHTML;
        const receivedDonation = (receivedDonationRef.current.innerHTML && receivedDonationRef.current.innerHTML === "")  
        ? 0 : receivedDonationRef.current.innerHTML;  


        if (parseFloat(expectedDonation) > parseFloat(receivedDonation)) {    

            var openDonationPopup = false;

            confirmAlert({
                title: 'Confirm to check out',
                message: 'Received donation amount does not match the expected donation amount!',
                buttons: [
                  {
                    label: 'Ignore & Check Out',
                    onClick: () => saveCheckOut(isFullReservation, strRoomBookingIds)
                  },
                  {
                    label: 'Add Donation',
                    onClick: () => openDonationPopup = true
                  }
                ],
                afterClose: () => {openDonationPopup && addDonationPopup()}
              });
        } else {
            saveCheckOut(isFullReservation, strRoomBookingIds);
        
        }

    }

    function saveCheckOut(isFullReservation, strRoomBookingIds) {

        const roomBookingsData = {
            r_id: isFullReservation === true ? roomBookings.reservationId : null, 
            str_room_booking_ids: isFullReservation === true ? null : strRoomBookingIds,
            user_id: sessionStorage.getItem('userId')   
        };

        store("apiCheckouts/", JSON.stringify(roomBookingsData))
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {
            setAlertMsg("Rooms checked out successfully!");
            setAlertColor("success");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                refreshReservation(result[0].reservation_status_id); //update the reservation status to checked out
            }, 2000);
            fetchRoomsBookings();
        })
        .catch((error) => {
            logError(error);
        });
    }

    function updateDonationDetails(updatedAmt) {
        document.getElementById("spReceivedDonation").innerHTML = updatedAmt;   
    }

    function addDonationPopup() {

        confirmAlert({
            customUI: ({ onClose }) => {
            return (
                <div className="divPageContainer">
                    <h3>Donation Details</h3>  
                    <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>  
                    <Suspense fallback={<div id="loader" class="loaderCenter"></div> }>           
                    <Donations rId = {roomBookings.reservationId} gId={roomBookings.guestId} onSave={(updatedAmt) => {updateDonationDetails(updatedAmt); onClose();}}></Donations> 
                    </Suspense>
                </div>
            )}
        })
    }

    function allRoomsCheckClicked() {   
        const chkAllRooms = document.getElementsByClassName("chkAllRooms");
        const chkAllRoomsCount = chkAllRooms.length;

        for (let i = 0; i < chkAllRoomsCount; i++) {
            if (document.getElementById("chkAllRoomsSelected").checked === true) {
                chkAllRooms[i].checked = true;
            } else {
                chkAllRooms[i].checked = false;
            }
        }
    }

    function downloadRoomsList(){

        const csvConfig = mkConfig({ 
            useKeysAsHeaders: true,
            filename: "RoomsList.csv",  
            showTitle: true,
            title: "Rooms List for " + reservationDetail.guestName + " from " + moment(reservationDetail.arrivalDate).format('DD-MMM-YY') + " to " + moment(reservationDetail.departureDate).format('DD-MMM-YY'),       
         });

        const bookings = roomBookings.roomBookingItems.map((item, index) => ({   
            No: index + 1,   
            Block: blocks[item.block_id],  
            RoomNo: item.room_no,
            Beds: item.total_beds,
            Floor: floors[item.floor_no],   
            AC: item.has_AC === 1 ? 'Yes' : 'No',   
            Cooler: item.has_cooler === 1 ? 'Yes' : 'No',       
            Arrival: moment(item.date_of_arrival).format('DD-MMM-YY'),  
            Departure: moment(item.date_of_departure).format('DD-MMM-YY')
        }));
        
        // Converts your Array<Object> to a CsvOutput string based on the configs
        const csv = generateCsv(csvConfig)(bookings);
        
        download(csvConfig)(csv);
    }


    return (
        <div className="page-container">  
            <h5>Allocated Rooms</h5>
                {/* <div className="div-block-totals grand-total" style={{ visibility: roomBookings.roomBookingItems.length > 0 ? 'visible' : 'hidden', display: roomBookings.roomBookingItems.length > 0 ? 'inline' : 'none' }}> */}

                <div className="div-block-totals grand-total">

                
                {
                    (parseInt(sessionStorage.getItem('reservationStatusId')) === 3 || parseInt(sessionStorage.getItem('reservationStatusId')) === 4) 
                    && roomBookings.roomBookingItems.length > 0 && //allocated or checked in
                     
                    <Link onClick={() => { downloadRoomsList() }} style={{ marginLeft: '2em', textDecoration: 'underline', fontWeight: 'bold' }}>
                        Download Rooms List
                    </Link>
                }

                {parseInt(sessionStorage.getItem('reservationStatusId')) === 3 && //allocated rooms available then show check in button
                    roomBookings.roomBookingItems.length > 0 &&
                    <button className="btnBig" style={{ marginTop: '0', marginLeft: '2em' }} 
                    onClick={() => checkInRooms()}>
                        Check In
                    </button>
                }


                {((
                    parseInt(sessionStorage.getItem('reservationStatusId')) === 3 || parseInt(sessionStorage.getItem('reservationStatusId')) === 4 ||
                    parseInt(sessionStorage.getItem('reservationStatusId')) === 6 || parseInt(sessionStorage.getItem('reservationStatusId')) === 7 ||
                    parseInt(sessionStorage.getItem('reservationStatusId')) === 8  
                ) //allocated, checked in, cancelled, no show, denied
                &&  
                (
                    <button 
                    className="btnBig" style={{ marginTop: '0', marginLeft: '2em', backgroundColor: 'darkcyan' }} 
                    onClick={() => { addMoreRooms() }}
                    >
                    Add Rooms
                    </button>  
                )
                )}
 
                {parseInt(sessionStorage.getItem('reservationStatusId')) == 4 &&  // checked in rooms available then show check out button
                    roomBookings.roomBookingItems.length > 0 &&
                    <button className="btnBig" style={{ marginTop: '0', marginLeft: '2em' }} 
                    onClick={() => checkOutRooms()}>
                        Check Out
                    </button>
                }
                     
              </div>
            <Alert color={alertColor} isOpen={showAlert}> {alertMsg} </Alert> 
            <div id="divRoomBookings" style={{height:'600px', overflowY:'auto'}}>

                <div className="table-container">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th><input type="checkbox" id="chkAllRoomsSelected" onChange={() => allRoomsCheckClicked()} /></th>
                                <th>Room No</th>
                                <th>Beds</th>
                                <th>Days x Amount</th>
                                <th>Arrival</th>
                                <th>Departure</th>
                                <th>Status</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roomBookings.roomBookingItems.map((item, index) => {
                                const statusClass = statusClasses[item.room_status_id] || '';

                                return (
                                    <tr key={item.room_booking_id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {(item.room_status_id === 2 || item.room_status_id === 3) ? (
                                                <input type="checkbox" name={item.room_id} className="chkAllRooms" id={item.room_booking_id} data-beds={item.total_beds} 
                                                    value={item.room_rent} /> ) : null}
                                        </td>
                                        <td>{item.room_no}<br/>
                                            <span style={{fontSize: '9pt'}}>
                                                {floors[item.floor_no]}, <b>{blocks[item.block_id]}</b>
                                            </span>
                                            <span className="sp-block-imgs">
                                                <img
                                                    alt="Has AC"
                                                    src={require("../img/ac.png")}
                                                    style={{
                                                        visibility: item.has_AC === 1 ? 'visible' : 'hidden',
                                                        display: item.has_AC === 1 ? 'inline' : 'none'
                                                    }}
                                                />
                                            </span>
                                        </td>
                                        <td>{item.total_beds}</td>
                                        <td>
                                            {item.block_id !== 13 ? (
                                                <>
                                                    {moment(item.date_of_departure).diff(moment(item.date_of_arrival), 'days')} x &#8377; {item.room_rent.toLocaleString('en-IN')}
                                                    &nbsp; = <b>&#8377; {((moment(item.date_of_departure).diff(moment(item.date_of_arrival), 'days')) * item.room_rent).toLocaleString('en-IN') }</b>    
                                                </>
                                            ) : (
                                                <>
                                                    <b>&#8377; {item.room_rent.toLocaleString('en-IN')}</b>
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <input type="date" name="date_of_arrival" 
                                                defaultValue={moment(item.date_of_arrival).format('YYYY-MM-DD')} 
                                                onChange={(e) => handleArrivalDateChange(item.room_booking_id, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="date" name="date_of_departure" 
                                                defaultValue={moment(item.date_of_departure).format('YYYY-MM-DD')} 
                                                min={moment(item.date_of_arrival).add(1, 'days').format('YYYY-MM-DD')}
                                                max={moment(item.next_arrival_date).format('YYYY-MM-DD')}
                                                onChange={(e) => handleDepartureDateChange(item.room_booking_id, e.target.value)}
                                            />
                                        </td>
                                        <td className={`room-status ${statusClass}`}>{roomStatuses[item.room_status_id]}</td>
                                        <td style={{textAlign: 'center'}}>
                                            {item.room_status_id === 4 ? null : (
                                                <img
                                                    alt="Remove Room"
                                                    src={require("../img/remove.png")}
                                                    onClick={() => removeRoom(item.room_booking_id)}    
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td><b>Total</b></td>
                                <td></td>
                                <td></td>
                                <td><b>{bedsCount}</b></td>
                                <td><b>&#8377;&nbsp;{totalAmount.toLocaleString('en-IN')}</b></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>                            
            </div>  
        </div>
      );    
}

export default RoomBookings;
