import { React, useState, useEffect } from "react";
import { fetch, store } from '../utils/httpUtil.js';
import { logError, checkError } from '../utils/helpers';
import { roomCategories, blocks, floors } from '../constants/roomAttributes.js';
import moment from 'moment';
import { Alert } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import "../index.css";
import "../css/allocate-rooms.css";

import AutoAssignedRooms from './AutoAssignedRooms';


function AllocateRooms({ reservationDetail, refreshReservation }) {

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const [roomsPoolDetails, setRoomsPoolDetails] = useState({
    reservationId: reservationDetail.reservationId,
    arrivalDate: moment(reservationDetail.arrivalDate).format('YYYY-MM-DD'),
    rStatusId: sessionStorage.getItem('reservationStatusId') === null ? reservationDetail.rStatusId : sessionStorage.getItem('reservationStatusId'),  
    departureDate: reservationDetail.departureDate,
    searchResultItems: []
  });


  const [uniqueBlocks, setUniqueBlocks] = useState([]);
  const [grandBeds, setGrandBeds] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);  
  const [arrBlockTotal, setArrBlockTotal] = useState({});
  const [arrBlockBeds, setArrBlockBeds] = useState({});
  const [checkedBlocks, setCheckedBlocks] = useState({});
  const [autoBeds, setAutoBeds] = useState(0);    
  const [autoTotal, setAutoTotal] = useState(0);  
  const [autoRooms, setAutoRooms] = useState([]); 

  useEffect(() => {
    fetchRoomsPool();
  }, []);

  useEffect(() => {
  }, [roomsPoolDetails]);

  useEffect(() => {
    roomCheckClicked();
  }, [autoTotal, autoBeds]);

  const fetchRoomsPool = () => {
    fetch("apiArooms/4?adate=" + roomsPoolDetails.arrivalDate + "&ddate=" + roomsPoolDetails.departureDate)
      .then((response) => checkError(response))
      .then((result) => {
        const arrSearchItems = result.map(item => ({
          room_id: item.room_id,
          room_no: item.room_no,
          floor_no: item.floor_no,
          total_beds: item.total_beds,
          room_category_id: item.room_category_id,
          room_rent: item.room_rent,
          block_id: item.block_id,
          has_AC: item.has_AC,
          previous_departure_date: item.previous_departure_date
        }));

        const uniqueBlocksResult = [...new Set(arrSearchItems.map(item => item.block_id))];
        setRoomsPoolDetails(prevDetails => ({
          ...prevDetails,
          searchResultItems: arrSearchItems
        }));
        setUniqueBlocks(uniqueBlocksResult);
        setIsLoaded(true);
      })
      .catch((error) => {
        logError("AllocateRooms " + error);
      });
  };

  const getAllSelectedRooms = () => {
    return uniqueBlocks.flatMap(blockId => {
      const checkboxes = document.getElementsByName(blocks[blockId]);
      return Array.from(checkboxes)
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.id);
    });
  };

  const createRoomsString = (selectedRooms) => {
    return selectedRooms.join('|');
  };

  const allocateRooms = (isJustAllocation) => {

    const selectedRooms = getAllSelectedRooms();

    const mergedRooms = [...selectedRooms, ...autoRooms]; //merge auto assigned rooms with selected rooms to be allocated manually
    const uniqueMergedRooms = [...new Set(mergedRooms)]; //remove duplicates  
     
    const str_rooms = createRoomsString(uniqueMergedRooms);

    const payload = {
      room_ids_str: str_rooms,
      is_allocated: isJustAllocation ? 1 : 0,
      user_id: sessionStorage.getItem('userId')
    };

    store("apiRoombookings/" + roomsPoolDetails.reservationId, JSON.stringify(payload))
      .then((response) => checkError(response))
      .then((result) => {
        setAlertMsg("Rooms added to the reservation successfully!");
        setAlertColor("success");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false); 
          refreshReservation(result[0].reservation_status_id); //refresh reservation details to update reservation status to allocated or checked in

          // if (isJustAllocation) {
          //   refreshReservation(3); //refresh reservation details to update reservation status to allocated
          // } else {
          //   refreshReservation(4); //refresh reservation details to update reservation status to checked in
          // }    
        }, 2000);

      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!");
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        logError(error);
      });
  };

  const selectBlockRooms = (blockId) => {
    const newCheckedBlocks = { ...checkedBlocks };    
    newCheckedBlocks[blockId] = !newCheckedBlocks[blockId];
    setCheckedBlocks(newCheckedBlocks);

    const chkAllRooms = document.getElementsByName(blocks[blockId]);
    const chkAllRoomsCount = chkAllRooms.length;

    for (let i = 0; i < chkAllRoomsCount; i++) {
        chkAllRooms[i].checked = !chkAllRooms[i].checked;
    }
    roomCheckClicked(blockId);
  };

  const roomCheckClicked = (blockId) => {

    const newBlockTotals = { ...arrBlockTotal };
    const newBlockBeds = { ...arrBlockBeds };

    let blockTotal = 0;
    let blockBeds = 0;

    const aDate = moment(roomsPoolDetails.arrivalDate);
    const dDate = moment(roomsPoolDetails.departureDate);

    const checkboxes = document.getElementsByName(blocks[blockId]);

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        if (blockId === 13) { //exclude event hall block 
          blockTotal += (parseFloat(checkbox.value));
        } else {
          blockTotal += (parseFloat(checkbox.value) * dDate.diff(aDate, 'days'));
          blockBeds += parseFloat(checkbox.getAttribute("data-beds"));
        }
      }
    });

    newBlockTotals[blockId] = blockTotal;
    newBlockBeds[blockId] = blockBeds;

    // Calculate grand totals
    let totalAmount = 0;
    let totalBeds = 0;

    for (let key in newBlockTotals) {
      totalAmount += newBlockTotals[key];
    }

    for (let key in newBlockBeds) {
      totalBeds += newBlockBeds[key];
    }

    setArrBlockTotal(newBlockTotals);
    setArrBlockBeds(newBlockBeds);

    setGrandTotal(parseFloat(totalAmount + autoTotal));
    setGrandBeds((totalBeds + autoBeds));

  };

  const updateAutoValues = (total, beds, selectedAssignedRooms) => { 
    setAutoTotal(total);
    setAutoBeds(beds);
    setAutoRooms(selectedAssignedRooms);    
  };

  useEffect(() => {
    updateGrandNos();
  }, [grandTotal, grandBeds]);

  const updateGrandNos = () => {
    document.getElementById("spGrandBeds").innerHTML = grandBeds;
  };

  return (
    <div className="page-container">  
      <h5>Allocate Rooms</h5>
      <div className="div-block-totals grand-total" style={{ visibility: roomsPoolDetails.searchResultItems.length > 0 ? 'visible' : 'hidden', display: roomsPoolDetails.searchResultItems.length > 0 ? 'inline' : 'none' }}>
        Grand Total &#8377;<span className="spanBold highlightNo highlightNo3" id="spGrandTotal">{grandTotal.toLocaleString('en-IN')}</span>
        <button className="btnBig" style={{marginTop:'0', marginLeft:'2em'}} onClick={() => allocateRooms(true)}>Allocate</button>
        {sessionStorage.getItem('reservationStatusId') === '2' && (//show below button only if reservation status is confirmed. Once allocated, no more auto assigned rooms}
          <button className="btnBig" style={{marginTop:'0', marginLeft:'2em', backgroundColor:'darkcyan'}} onClick={() => allocateRooms(false)}>Allocate & Check In</button> 
        )}
      </div>
      <Alert color={alertColor} isOpen={showAlert}> {alertMsg} </Alert> 
      <div id="divSearchResults" style={{height:'600px', overflowY:'auto'}}>
          {isLoaded && sessionStorage.getItem('reservationStatusId') === '2' && //show auto assigned rooms only if reservation status is confirmed. Once allocated, no more auto assigned rooms  
              (
                  <AutoAssignedRooms rId={roomsPoolDetails.reservationId} updateAutoValues={updateAutoValues} arrivalDate = {roomsPoolDetails.arrivalDate} departureDate = {roomsPoolDetails.departureDate} />
              )
          }

          {uniqueBlocks.length === 0 && <h5>No rooms available for allocation</h5>} 

          {uniqueBlocks.length > 0 && uniqueBlocks.map(item => (
            <div className="divBlocks" key={item}>
                  {item != 13 && (//hide check all for event hall block
                  <input type="checkbox" name="chkAllBlockRooms" id={`blk_${item}`}
                    checked={checkedBlocks[item] || false}
                    onChange={(e) => selectBlockRooms(item, e)}
                  />
                  )}
                  <h4 style={{margin: '-1.3em 0 0 1.5em'}}>{blocks[item]}</h4>
                  <span className="div-block-totals">
                    Total &#8377;<span>{arrBlockTotal[item] ? arrBlockTotal[item].toLocaleString('en-IN') : 0}</span>
                </span>
                  {item != 13 && (//hide beds for event hall block
                    <span className="div-block-beds">Beds <span>{arrBlockBeds[item]}</span></span>  
                  )}
                  <ul>
                      {roomsPoolDetails.searchResultItems.filter(bk => bk.block_id === item).map(booking => (
                        <li key={booking.room_id} 
                        style={booking.previous_departure_date !== null && moment(booking.previous_departure_date).isSame(moment(), 'day') ? { backgroundColor: 'lightyellow' } : null}>
                          <input
                            type="checkbox"
                            name={blocks[item]}
                            className="chkAllRooms"
                            id={booking.room_id}
                            data-beds={booking.total_beds}
                            value={booking.room_rent}
                            onChange={(e) => roomCheckClicked(item, e)}   
                          />
                          <b>{booking.room_no}</b>
                          {", " + floors[booking.floor_no]}
                          {booking.block_id != 13 ? ", " + booking.total_beds + " beds" : ""} 
                            <>
                                  <span className="sp-block-total">
                                    &#8377;{booking.room_rent.toLocaleString('en-IN') + " "}
                                  </span>
                                  <span className="spEventHallLegend">
                                      <img
                                        alt="Previous Check Out"
                                        src={require("../img/prev_date.png")}
                                        style={{
                                          visibility: booking.previous_departure_date != null ? 'visible' : 'hidden',
                                          display: booking.previous_departure_date != null ? 'inline' : 'none',
                                          marginLeft: '0.5em'
                                        }}
                                      />
                                      {booking.previous_departure_date != null
                                            ? moment(booking.previous_departure_date).format("YYYY-MM-DD") : null}
                                  </span>
                                  {booking.room_category_id != null && (
                                      <span className="sp-block-imgs">
                                          <img
                                              alt="Has AC"
                                              src={require("../img/ac.png")}
                                              style={{
                                                visibility: booking.has_AC === 1 ? 'visible' : 'hidden',
                                                display: booking.has_AC === 1 ? 'inline' : 'none'
                                              }}
                                          />
                                      </span>
                                  )}
                            </>           
                        </li>
                      ))}
                  </ul>
            </div>
          ))}
      </div>
    </div>
  );
}

export default AllocateRooms;
