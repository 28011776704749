import React, {useEffect} from 'react';

import ErrorBoundary from '../ErrorBoundary'

import RequestsCount from './RequestsCount.js';
import AllRequests from './AllRequests.js';

// core components
import Header from "./Header";
import { useNavigate } from "react-router-dom";

function RequestsContainer() {

    const navigate = useNavigate(); 

    useEffect(() => {
        if (sessionStorage.getItem('authenticated') !== 'true'){
          navigate("/", { replace: true });
        }
    }, []); 
    
    return (
            <>
            <Header selected="Requests"/>

            <div className="divError">    
            <ErrorBoundary>
            <div className="divPageContainer">
            <h3>Requests</h3>   
            <hr/>
            <div className = "div-table">
                    <div className = "div-table-row">
                          <div className ="div-table-col">
                              <RequestsCount /> 
                          </div>
                          <br/>
                    </div>
                    <div className = "div-table-row">
                           <div className ="div-table-col">
                              <AllRequests />
                          </div>
                    </div>
                    
              </div>
            </div>
            </ErrorBoundary>
            </div>
            </>
  );
}

export default RequestsContainer;
