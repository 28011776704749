import {React, useState, useEffect} from "react";

import { fetch } from '../utils/httpUtil.js';
import { logError, checkError } from '../utils/helpers';
import { blocks, floors} from '../constants/roomAttributes.js';

import moment from 'moment';  

import "../css/allocate-rooms.css";

import {Alert} from 'reactstrap';

//var arrAutoAssignedRooms = [];  

function AutoAssignedRooms({ rId, updateAutoValues, arrivalDate, departureDate }) {

  const [reservationId, setReservationId] = useState(rId);  
  const [aDate, setADate] = useState(moment(arrivalDate));  
  const [dDate, setDDate] = useState(moment(departureDate));
  
  const [autoAssignedRooms, setAutoAssignedRooms] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();

  const [totalAssignedAmount, setTotalAssignedAmount] = useState(0);
  const [totalAssignedBeds, setTotalAssignedBeds] = useState(0);
  const [selectedAssignedRooms, setSelectedAssignedRooms] = useState([]);   
  
  
  const fetchAutoAssignedRooms= async () => {

    fetch("apiRoomBookings/" + reservationId + "?type=1")
      .then((response) => {
        return checkError(response);
      })
      .then((result) => {      
        
        const initialTotal = result.reduce((acc, room) => ({
          totalBeds: acc.totalBeds + room.total_beds,
          totalAmount: acc.totalAmount + (room.room_rent * dDate.diff(aDate, 'days'))
        }), { totalBeds: 0, totalAmount: 0 });


        setAutoAssignedRooms(result.map(room => ({ ...room, checked: true })));
        setTotalAssignedBeds(initialTotal.totalBeds);
        setTotalAssignedAmount(initialTotal.totalAmount);
         
      })
      .catch((error) => {
        logError(this.constructor.name + " " + error);
      });
  }

  const updateTotals = () => {  

        /* auto assigned rooms total amount and beds */ 
        const autoCheckboxes = document.getElementsByClassName("chkAllAutoAssignedRooms");

        let assignedTotalAmount = 0;
        let assignedTotalBeds = 0;    
    
        if (autoCheckboxes.length != 0) {
          for (let i = 0; i < autoCheckboxes.length; i++) { 
            if (autoCheckboxes[i].checked) {
              assignedTotalAmount += (parseFloat(autoCheckboxes[i].value) * dDate.diff(aDate, 'days')); 
              assignedTotalBeds += parseFloat(autoCheckboxes[i].getAttribute("data-beds"));
              selectedAssignedRooms.push(autoCheckboxes[i].id); 
            } else {
              const index = selectedAssignedRooms.indexOf(autoCheckboxes[i].id);
              if (index > -1) {
                selectedAssignedRooms.splice(index, 1);
              }              
          }
        }
      }

        updateAutoValues(assignedTotalAmount, assignedTotalBeds, selectedAssignedRooms); 
        

  };

  useEffect(() => {    
    fetchAutoAssignedRooms();
  }, []);

  useEffect(() => {
    updateTotals();
  }, [autoAssignedRooms, totalAssignedAmount, totalAssignedBeds]);

  const handleCheckboxChange = (roomId) => {

    setAutoAssignedRooms(prevState => {
      const updatedRooms = prevState.map(room => {
        if (room.room_id === roomId) {
          room.checked = !room.checked;
        }
        return room;
      });

      const newTotalAssignedBeds = updatedRooms.reduce((acc, room) => room.checked ? acc + room.total_beds : acc, 0);
      const newTotalAssignedAmount = updatedRooms.reduce((acc, room) => room.checked ? acc + (room.room_rent * dDate.diff(aDate, 'days')) : acc, 0);

      setTotalAssignedBeds(newTotalAssignedBeds);
      setTotalAssignedAmount(newTotalAssignedAmount);

      updateTotals();

      return updatedRooms;
    });
  };


      return (
        <>
        <div className="page-container"> 
              <Alert color={alertColor} isOpen={showAlert}> {alertMsg} </Alert> 
              {autoAssignedRooms.length > 0 && (  
                  <div className="divBlocks">  
                    <h5>Auto Assigned Rooms</h5>     

                      <span className="div-block-totals">Total &#8377;<span id="autoAssignedRoomsBlock">{totalAssignedAmount.toLocaleString('en-IN')}</span></span>
                      <span className="div-block-beds">Beds <span id="autoAssignedRoomsBlock_beds">{totalAssignedBeds}</span></span>
                      <ul>
                          {autoAssignedRooms.map(booking => (
                            <li key={booking.room_id} style={{ backgroundColor: 'rgb(174, 219, 82)' }}>
                                  <input
                                    type="checkbox"
                                    name={booking.room_booking_id}
                                    className="chkAllAutoAssignedRooms"
                                    id={booking.room_id}
                                    data-beds={booking.total_beds}
                                    value={booking.room_rent}
                                    checked={booking.checked}
                                    onChange={() => handleCheckboxChange(booking.room_id)}
                                  />
                                  <b>{blocks[booking.block_id] + " - " + booking.room_no}</b>
                                  {", " + floors[booking.floor_no] + ", " + booking.total_beds + " beds"}
                                  {booking.room_category_id != null && (
                                    <>
                                      <span className="sp-block-total">
                                        &#8377;{booking.room_rent.toLocaleString('en-IN') + " "}
                                      </span>
                    
                                      <span className="sp-block-imgs">
                                        <img
                                          alt="Has AC"
                                          src={require("../img/ac.png")}
                                          style={{
                                            visibility: booking.has_AC === 1 ? 'visible' : 'hidden',
                                            display: booking.has_AC === 1 ? 'inline' : 'none'
                                          }}
                                        />
                                      </span> 

                                    </>
                                  )}
                            </li>
                          ))}

                      </ul>
                  </div>
              )}

        </div>
        </>
      );    

}

export default AutoAssignedRooms;
