

export function logError(error) {
    const payload = {
        error_message: error.toString()
    }

    fetch("/apiError/", {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
  
      })
      .then(function(response) {
          return response.json()
      })
}

export function checkError(response) {
    if (response.status === 200) {
        return response.data;
      } 
     else if (response.status === 403){
        this.failedLogin();
     } 
    else {
        throw new Error(response.statusText);
    }
}

export function createRoomsString(selectedRooms){
    //loop through selected rooms and create a | separated string to pass to POST
    var str_rooms = "";
    for (var i =0; i < selectedRooms.length; i++)
    {  
      str_rooms+= selectedRooms[i] + "|";
    }
    str_rooms = str_rooms.substring(0,str_rooms.length-1);
    return str_rooms;
}


export function failedLogin(){
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userName');
    
    //notify.show("User doesn't have access to portal!", "error");

    setTimeout(this.redirectAgain, 2000);
}