import React, {useState, useEffect} from "react";
import ErrorBoundary from '../ErrorBoundary'
import Header from "./Header";  
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {fetch} from '../utils/httpUtil';    
import {arrReservationTypes, reservationTypes, reservationStatuses} from '../constants/roomAttributes';   

import { Alert, Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';

import { checkError, logError } from '../utils/helpers';


import "../css/reports.css";


const Reservations = () => { 

    const [reservationType, setReservationType] = useState(0);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [searchText, setSearchText] = useState('');

    const [reservationItems, setReservationItems] = useState([]);

    const [showReservation, setShowReservation] = useState(false);   
    
    const [alertMsg, setAlertMsg] = useState();
    const [alertColor, setAlertColor] = useState();
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.searchText) {
            setSearchText(location.state.searchText);
        }
    }, [location.state]);

    useEffect(() => {
        if (searchText) {
            handleReservationSearch();
        }
    }, [searchText]);

        // Update endDate when startDate changes
    useEffect(() => {
        if (startDate > endDate) {
            setEndDate(startDate); // Set endDate to startDate if it's less than startDate
        }
    }, [startDate]); // Runs effect whenever startDate changes


    function loadReservationType(reservationType){  
        setShowReservation(false);
        setReservationType(reservationType);
        setSearchText('');  

    }

    function handleGenerateReservations() {    
        
        fetch(`apiReservations/?type=${reservationType}&adate=${moment(startDate).format('YYYY-MM-DD')}&ddate=${moment(endDate).format('YYYY-MM-DD')}`)
        .then((response) => checkError(response))
        .then((result) => {  
            if (result.length === 0) {  
                showNoReservationMsg();
                return;
            }
            setShowReservation(true);
            setReservationItems(result);               
        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000); 
            logError(error);
        });
    }; 

    function showReservationDetails(rTypeID, rStatusID, rID, gID, guestName) {

        const reservationItem = {
            rTypeId: rTypeID, 
            rStatusId: rStatusID,   
            rId: rID, 
            gId: gID, 
            guestName: guestName
        }

        var sourcePageId = 4; //Reservations Page  

        navigate("/reservationContainer", { state: { reservationItem, sourcePageId } });
    }

    function handleSearchInputChange(event) {
        setShowReservation(false);
        setSearchText(event.target.value);
        setReservationType(0); 
    }   

    function handleKeyUp(event){
        event.preventDefault();
        if (event.keyCode === 13) {
           handleReservationSearch();
        }
    }

    function handleReservationSearch() {   
        fetch(`apiGuests/?search=${searchText}`)
        .then((response) => checkError(response))
        .then((result) => {     
            if (result.length === 0) {  
                showNoReservationMsg();
                return;
            }
            setShowReservation(true);
            setReservationItems(result);               
        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000); 
            logError(error);
        });
    }

    function showNoReservationMsg() {
        setAlertMsg("No reservations found!");
        setAlertColor("info");
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
          }, 2000)
    }
    
    return (
        <>
        <Header selected="Reservations"/>
        <div className="divError">    
        <ErrorBoundary>
        <div className="divPageContainer">
        <h3>Active Reservations</h3>  
            <hr/>
                <Alert color={alertColor} isOpen={showAlert}>  
                    {alertMsg}
                </Alert>

                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label className="form-label">
                                Reservation Type:
                            </Label>
                            <Input type="select" id="slReservationTypes" required className="form-control" value = {reservationType}   
                                onChange={(e) => loadReservationType(e.target.value)}>  
                                    <option value="0">All</option>    
                                    {arrReservationTypes.map((item, index) => (
                                        <option key={index+1} value={index+1}>{item}</option>
                                    ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label className="form-label">
                                Start Date:
                            </Label>
                            <Input type="date" required className="form-control" value = {startDate}
                                onChange={(e) => setStartDate(e.target.value)} />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label className="form-label">
                                End Date:
                            </Label>
                            <Input type="date" required className="form-control" value = {endDate}  min={startDate}
                                onChange={(e) => setEndDate(e.target.value)} />
                        </FormGroup>
                    </Col>  
                    <Col md={2}>
                        <button className="btnBig" onClick={() => handleGenerateReservations()}>Show</button>
                    </Col>
                    <Col md={4} style={{marginLeft:'-3em'}}>
                        <div className="divFloatRight" style={{width:'75%'}}>
                        <FormGroup>
                            <Label className="form-label">
                                &nbsp;
                            </Label>
                            <Input type="text" value = {searchText}   
                                onChange={handleSearchInputChange}  
                                autoComplete="off"
                                placeholder="Search by email id or phone"
                                onKeyUp={(event) => handleKeyUp(event)}                         
                                className="form-control email-search"/>
                                <div className="button-holder" style={{float:'right', marginTop:'-1.9em', height:'25px', marginRight: '0.5em'}}>
                                    <img id="imgSearch" src={require("../img/magnifying_glass.png")} alt="Print" style={{height:'25px', width:'25px'}} onClick={() => handleReservationSearch()}></img>
                                </div>
                        </FormGroup>                                
                               
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{marginLeft:'-2em', backgroundColor:'unset'}}>
                        {reservationItems.length > 0 && showReservation ? (  
                            <div className="divReportContainer" style={{border:'solid 1px beige',backgroundColor:'white', padding:'2em', margin:'2em 0 0 1em', textAlign: 'center', fontFamily: 'Segoe UI'}}>
                                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                                        Showing Active Reservations for
                                        {searchText === '' ? (
                                        <>
                                            {reservationType === 0 ? (
                                            ' All Reservation Types'
                                            ) : (
                                            ` ${arrReservationTypes[reservationType - 1]}`
                                            )}
                                            {` from ${moment(startDate).format('DD-MMM-YY')} to ${moment(endDate).format('DD-MMM-YY')}`}
                                        </>
                                        ) : (
                                        ` ${searchText}`
                                        )}
                                </span>
                                <br/>
                            
                                <div className="table-container">
                                    <table className="simple-table">
                                        <thead>
                                            <tr>
                                                <th>Reservation Type</th>
                                                <th>Guest Name</th>
                                                <th>Arrival Date</th>
                                                <th>Departure Date</th>
                                                <th>Reservation Status</th>
                                                <th>Pax</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reservationItems.map(item => {
                                                    const typeClass = reservationTypes[item.reservation_type_id].toLowerCase();
                                                    const statusClass = reservationStatuses[parseInt(item.reservation_status_id)].toLowerCase().replace(/\s/g, '');
                                                    return (

                                                    <tr key={Math.random()} className="selectable-row" onClick={() => showReservationDetails(item.reservation_type_id, item.reservation_status_id, item.reservation_id, item.guest_id, item.guest_name)}>
                                                        <td><span className={`reservation-item ${typeClass}`}>{reservationTypes[item.reservation_type_id]}</span></td>
                                                        <td>{item.guest_name}</td>
                                                        <td>{moment(item.date_of_arrival).format('DD-MMM-YY')}</td>
                                                        <td>{moment(item.date_of_departure).format('DD-MMM-YY')}</td>
                                                        <td className={`reservation-item ${statusClass}`} style={{fontSize:'16pt', fontWeight:'500'}}>{reservationStatuses[item.reservation_status_id]}</td>
                                                        <td>{item.no_of_people}</td>
                                                    </tr>
                                                    );  
                                            })} 
                                        </tbody>
                                    </table>
                                </div>
                                </div>) :  null                                       
                        }  
                    </Col>
                </Row>
        </div>
        </ErrorBoundary>
        </div>

        </>
    );

}

export default Reservations;