import React from 'react';
import {store} from './utils/httpUtil';
import {checkError} from './utils/helpers';

export class ErrorBoundary extends React.Component {
    constructor(){
        super()
        this.state = {
            hasError:false
        }
    }

    componentDidCatch(error, info){
        
        this.setState({ hasError: true })
        //send to error reporting service

        const payload = {
            error_message: error.toString() + " - " + info.toString()
        }

        store("/apiError/", JSON.stringify(payload))
          .then((response) => {
            return checkError(response);
          });
    }

    render(){
        if (this.state.hasError){
            return (
                <div class="divErrorMsg">Oh no, something went wrong! </div>
            )
        } else{
            return this.props.children;
        }
        
    }
}

export default ErrorBoundary;