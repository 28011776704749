import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./css/bootstrap.min.css";
import "./css/now-ui-kit.css";
import './index.css';

import Login from "./Login.js";
import Dashboard from "./components/Dashboard.js";
import RequestsContainer from "./components/RequestsContainer.js";   
import RequestsCount from "./components/RequestsCount.js";              
import AllRequests from "./components/AllRequests.js";      

import Reservations from './components/Reservations';   
import ReservationContainer from './components/ReservationContainer';   
import ProcessRequest from './components/ProcessRequest';       
import ReservationDetails from './components/ReservationDetails';       
import CheckInForm from './components/CheckInForm';   
import Walkins from './components/WalkIns';
import AvailabilityCalendar from './components/AvailabilityCalendar';   
import RoomsCalendar from './components/RoomsCalendar'; 
import EventsCalendar from './components/EventsCalendar';       
import Reports from './components/Reports';
import Rooms from './components/Rooms'; 
import References from './components/References';       
import EmailTemplates   from './components/EmailTemplates';     
import ErrorBoundary from './ErrorBoundary';
      

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
<React.StrictMode>
    <BrowserRouter>
<ErrorBoundary>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="dashboard" element={<Dashboard />} />      
        <Route path="requests" element={<RequestsContainer />} />        
        <Route path="requestsCount" element={<RequestsCount />} />      
        <Route path="allRequests" element={<AllRequests />} />  

        <Route path="reservations" element={<Reservations />} />     
        <Route path="reservationContainer" element={<ReservationContainer />} />        

        <Route path="checkInForm" element={<CheckInForm />} />  

        <Route path="walkins" element={<Walkins />} />  

        <Route path="AvailabilityCalendar" element={<AvailabilityCalendar />} />    
        <Route path="roomsCalendar" element={<RoomsCalendar />} />             
        <Route path="eventsCalendar" element={<EventsCalendar />} />      

        <Route path="confirmReservation" element={<ProcessRequest />} />    
        <Route path="reservationDetails" element={<ReservationDetails />} />

        <Route path="reports" element={<Reports />} />
        <Route path="rooms" element={<Rooms />} />      
        <Route path="references" element={<References />} />
        <Route path="emailTemplates" element={<EmailTemplates />} />

      </Routes>
</ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);