import React, { useState, useEffect, Suspense, lazy } from "react";
import ErrorBoundary from '../ErrorBoundary';
import Header from "./Header";  
import moment from "moment";
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import "../css/reports.css";

const ReservationReport = lazy(() => import('./reports/ReservationReport'));
const CheckInReport = lazy(() => import('./reports/CheckInReport'));    
const CheckOutReport = lazy(() => import('./reports/CheckOutReport'));
const UnoccupiedRoomsTodayReport = lazy(() => import('./reports/UnoccupiedRoomsTodayReport'));  
const EarlyCheckInReport = lazy(() => import('./reports/EarlyCheckInReport'));  

const Reports = () => { 
    const [reportType, setReportType] = useState('');
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [showReport, setShowReport] = useState(false);    

    const arrReportTypes = ["Reservations", "Check Ins", "Check Outs", "Today's Unoccupied Rooms", "Tomorrow's Early Check Ins"];
   
    function loadReportType(reportType){  
        setReportType(reportType);
        setShowReport(false);   
    }

    const handleGenerateReport = async () => {
        if (reportType !== '') {
            setShowReport(true);
        }
    };

    function printReport() {
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>');
        if (reportType === '4') {
            printWindow.document.write("Today's Unoccupied Rooms");
        } else if (reportType === '5') {   
            printWindow.document.write("Tomorrow's Early Check Ins");
        }
        else {    
            printWindow.document.write(arrReportTypes[(document.getElementById("slReport").value - 1)] + ' ' + startDate + ' to ' + endDate);
        }
        printWindow.document.write(' Report</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(document.getElementsByClassName('divReportContainer')[0].innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }  

    // Update endDate when startDate changes
    useEffect(() => {
        if (startDate > endDate) {
            setEndDate(startDate); // Set endDate to startDate if it's less than startDate
        }
    }, [startDate]); // Runs effect whenever startDate changes

    return (
        <>
            <Header selected="Reports"/>
            <div className="divError">    
                <ErrorBoundary>
                    <div className="divPageContainer">
                        <h3>Reports</h3>  
                        <hr/>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="form-label">Report Type:</Label>
                                    <Input type="select" id="slReport" required className="form-control" value={reportType} onChange={(e) => loadReportType(e.target.value)}>  
                                        <option value="">Select a report</option>
                                        {arrReportTypes.map((item, index) => (
                                            <option key={index + 1} value={index + 1}>{item}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            {(reportType === '1' || reportType === '2' || reportType === '3') && (
                                <>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label className="form-label">Start Date:</Label>
                                            <Input type="date" required className="form-control" value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label className="form-label">End Date:</Label>
                                            <Input type="date" required className="form-control" value={endDate} min={startDate} 
                                                onChange={(e) => setEndDate(e.target.value)} />
                                        </FormGroup>
                                    </Col> 
                                </>
                            )} 
                                    <Col md={2} style={{ marginRight: '-1.5em' }}>
                                        <button className="btnBig" onClick={() => handleGenerateReport()}>Show</button>
                                    </Col>

                        </Row>
                        <Row>
                            <Col md={12} style={{ marginLeft: '-1em', backgroundColor: 'unset', padding: '0' }}>
                                {showReport && ( 
                                    <div className="divPrintIcon">
                                        <img src={require("../img/printer.png")} alt="Print" onClick={() => printReport()}></img>
                                    </div>
                                )}
                                {reportType === '1' && showReport && (
                                    <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                                        <ReservationReport sDate={startDate} eDate={endDate} />
                                    </Suspense>
                                )}
                                {reportType === '2' && showReport && (
                                    <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                                        <CheckInReport sDate={startDate} eDate={endDate} />
                                    </Suspense>
                                )}
                                {reportType === '3' && showReport && (
                                    <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                                        <CheckOutReport sDate={startDate} eDate={endDate} />
                                    </Suspense>
                                )}  
                                {reportType === '4' && showReport && (
                                    <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                                        <UnoccupiedRoomsTodayReport />
                                    </Suspense>
                                )}   
                                {reportType === '5' && showReport && (
                                    <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                                        <EarlyCheckInReport />
                                    </Suspense>
                                )}                               
                            </Col>
                        </Row>
                    </div>
                </ErrorBoundary>
            </div>
        </>
    );
}

export default Reports;
