import React, { useState, useEffect } from 'react';

import ErrorBoundary from '../ErrorBoundary'  
import Header from './Header';

import { useNavigate } from 'react-router-dom'; 

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";

import "../css/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import {logError, checkError} from '../utils/helpers';
import {fetch, store} from '../utils/httpUtil';
import { Row, Col, Button, FormGroup, Label, Input, Alert } from 'reactstrap';


function EmailTemplates() {

  const navigate = useNavigate();

  const [templateItems, setTemplateItems] = useState([]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());  
  const [hideToolbar, setHideToolbar] = useState(true); 

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();

  useEffect(() => { 

    if (sessionStorage.getItem('authenticated') === 'true'){
      populateTemplates();  
    } else {
      navigate("/", { replace: true });
    }


    if (hideToolbar === true){
      document.getElementsByClassName("editorClassName")[0].style.visibility = "hidden"; 
      document.getElementById("btnTemplateSave").style.visibility = "hidden";
    } else
    {
      document.getElementsByClassName("editorClassName")[0].style.visibility = "visible"; 
      document.getElementById("btnTemplateSave").style.visibility = "visible";
    }

  }, []);   


    function populateTemplates() {

      fetch("apiEmailtemplate")
        .then((response) => {
        return checkError(response);
      })
      .then((result) => { 
        setTemplateItems(result); 
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!")
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000); 
        logError(error);
      });
    }

    function handleTemplateChange(){
        if (document.getElementById("email_template_id").value === 0){
          setHideToolbar(true); 
          document.getElementsByClassName("editorClassName")[0].style.visibility = "hidden"; 
          document.getElementById("btnTemplateSave").style.visibility = "hidden";

        } else 
        {
          setHideToolbar(false);  
          document.getElementsByClassName("editorClassName")[0].style.visibility = "visible"; 
          document.getElementById("btnTemplateSave").style.visibility = "visible";

          var email_content = '';
          fetch("/apiEmailtemplate/" + document.getElementById("email_template_id").value)
              .then((response) => {
              return checkError(response);
              })
              .then((result) => {

                  email_content = result[0].email_template_content;

                  const contentBlock = htmlToDraft(email_content);
                  if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);

                    setEditorState(editorState);  
                  }
                })
              .catch((error) => {
                  //notify.show('Oops! Something went wrong! Please try again!', 'error');
                  logError(constructor.name + " " + error);
              });
          }
    }

    function onEditorStateChange(editorState){
      setEditorState(editorState);
    };

    function validateTemplateContent(){
        const payload = {
          email_template_id: document.getElementById("email_template_id").value,
          email_template_content:  draftToHtml(convertToRaw(editorState.getCurrentContent()))    
        };
    
        store("/apiEmailtemplate/", JSON.stringify(payload))
        .then((response) => {
          return checkError(response);
        })
        .then(
            (result) => {
              setHideToolbar(true); 
              document.getElementsByClassName("editorClassName")[0].style.visibility = "hidden"; 
              document.getElementById("btnTemplateSave").style.visibility = "hidden";
              setAlertMsg("Template saved successfully.")
              setAlertColor("success");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                document.getElementById("email_template_id").value = 0; 
              }, 2000); 
        })
        .catch((error) => {
          setAlertMsg("Oops! Something went wrong! Please try again!")
          setAlertColor("danger");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000); 
          logError(error);
        });
    }

        return (  
          <>
          <Header selected="Reports"/>

          <div className="divError">    
          <ErrorBoundary>
          <div className="divPageContainer">
          <h3>Email Templates</h3> 
          <hr/>
          <Alert color={alertColor} isOpen={showAlert}>
            {alertMsg}
          </Alert>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label className="form-label">
                              Template Name:
                            </Label>
                            <Input type="select" id="email_template_id" className="form-control" onChange={() => handleTemplateChange()}>
                                <option value="0">Please select</option>
                                { templateItems.map((item, index) => (
                                <option key={index} value={item.email_template_id}>{item.email_template_name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                <br/><br/><br/>
                </Row>
                <Row>
                    <Col md={12} style={{marginLeft:'-1.2em', backgroundColor:'unset'}}>
                    <div className="save-button-container" style={{marginBottom: '-3em', marginRight:'1em'}}>
                        <button id="btnTemplateSave" className="btnBig" onClick={() => validateTemplateContent()}>Save</button>
                      </div>

                        <Editor 
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={onEditorStateChange}
                              toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove'],
                                inline: {
                                  options: ['bold', 'italic', 'underline'] 
                                },
                              }}
                              toolbarHidden={hideToolbar}
                        /> 
                  </Col>
              </Row>
                   
                </div>
                </ErrorBoundary>
                </div>
                </>
            );
}

export default EmailTemplates;
