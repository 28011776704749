import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetch, store } from '../utils/httpUtil.js';
import { logError, checkError } from '../utils/helpers';
import { roomCategories } from '../constants/roomAttributes.js';

import "../css/react-confirm-alert.css";
import "../css/confirm-reservation.css";

import { Alert } from 'reactstrap';

function ProcessRequest({ reservationDetail }) {
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [arrSearchItems, setArrSearchItems] = useState([]);

  const [confirmDetails, setConfirmDetails] = useState({
    reservationId: reservationDetail.rId,
    guestId: reservationDetail.gId,
    guestName: reservationDetail.guestName,
    arrivalDate: reservationDetail.dateArrival,
    departureDate: reservationDetail.dateDeparture,
    emailId: reservationDetail.emailId,
    rTypeId: reservationDetail.rTypeId,
    sanskaraId: reservationDetail.sanskaraId,
    noOfPpl: reservationDetail.noOfPpl,
    searchResultItems: [],
    isConfirmed: null,
    sendEmail: false
  });

  useEffect(() => {
    checkCategoryAvailability();
  }, [confirmDetails.arrivalDate, confirmDetails.departureDate]);

  const checkCategoryAvailability = () => {

    fetch(`apiArooms/5?adate=${confirmDetails.arrivalDate}&ddate=${confirmDetails.departureDate}`)
      .then((response) => {
        const totalCount = response.headers.get('X-Total-Count');
        return checkError(response);
      })
      .then((result) => {
        const newArrSearchItems = [];
        for (let i = 0; i < result.length; i++) {
          newArrSearchItems.push({
            room_category_id: result[i].room_category_id,
            available_rooms: result[i].available_rooms
          });
        }
        setArrSearchItems(newArrSearchItems);
        setConfirmDetails((prevDetails) => ({
          ...prevDetails,
          searchResultItems: newArrSearchItems
        }));
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!");
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        logError(error);
      });
  };

  const validateRequestCount = () => {
    const searchResultItems = confirmDetails.searchResultItems.map(item => {
      const inputElement = document.getElementById("txt" + item.room_category_id);
      const inputValue = parseInt(inputElement.value);
      if (inputValue > item.available_rooms) {
        inputElement.value = item.available_rooms;
      }
      return {
        ...item,
        requested_rooms: inputValue
      };
    });
    populateGrandBeds(searchResultItems);
  };

  const populateGrandBeds = (searchResultItems) => {
    let totalBeds = 0;
    searchResultItems.forEach(item => {
      const inputValue = document.getElementById("txt" + item.room_category_id).value;
      const bedsInCategory = inputValue * item.room_category_id;
      totalBeds += bedsInCategory;
    });
    document.getElementById("spGrandBeds").innerHTML = totalBeds;
  };

  const processRequest = (e) => {
    if (e.target.value === "Confirm") {
      setConfirmDetails({ ...confirmDetails, isConfirmed: true });
    } else {
      setConfirmDetails({ ...confirmDetails, isConfirmed: false });
    }
  };

  const checkIfEmailToBeSent = (e) => {
    setConfirmDetails({ ...confirmDetails, sendEmail: e.target.checked });
  };

  const saveRequest = () => {
    if (confirmDetails.isConfirmed === null) {
      setAlertMsg("Please select Confirm or Deny!");
      setAlertColor("danger");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } else if (confirmDetails.isConfirmed === true && parseInt(document.getElementById("spGrandBeds").innerHTML) === 0) {
      setAlertMsg("Please allocate the rooms before saving!");
      setAlertColor("danger");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } else {
      if (confirmDetails.isConfirmed === true) {
        confirmRequest();
      } else {
        denyRequest();
      }
    }
  };

  const confirmRequest = () => {
    const roomCategories = arrSearchItems.map(item => {
      if (parseInt(document.getElementById("txt" + item.room_category_id).value) > 0) {
        return item.room_category_id + '|' + document.getElementById("txt" + item.room_category_id).value;
      }
      return null;
    }).filter(Boolean);

    const roomCategoriesString = roomCategories.join(',');

    const payload = {
      reservation_id: confirmDetails.reservationId,
      guest_id: confirmDetails.guestId,
      guest_name: confirmDetails.guestName,
      email_id: confirmDetails.emailId,
      arrival_date: confirmDetails.arrivalDate,
      departure_date: confirmDetails.departureDate,
      reservation_type_id: confirmDetails.rTypeId,
      sanskara_id: confirmDetails.sanskaraId,
      no_of_people: confirmDetails.noOfPpl,
      str_room_categories: roomCategoriesString,
      is_confirmed: confirmDetails.isConfirmed,
      send_email: confirmDetails.sendEmail,
      user_id: sessionStorage.getItem('userId')
    };

    console.log("payload: " + JSON.stringify(payload)); 

    store("apiRequests/1", payload)
      .then((response) => checkError(response))
      .then(() => {
        setAlertMsg("Reservation request confirmed successfully!");
        setAlertColor("success");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/requests", { replace: true });
        }, 2000);
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!");
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        logError(error);
      });
  };

  const denyRequest = () => {
    const data = {
      reservation_id: confirmDetails.reservationId,
      guest_name: confirmDetails.guestName,
      arrival_date: confirmDetails.arrivalDate,
      departure_date: confirmDetails.departureDate,
      no_of_people: confirmDetails.noOfPpl,
      send_email: confirmDetails.sendEmail,
      email_id: confirmDetails.emailId,
      user_id: sessionStorage.getItem('userId')
    };

    store("apiRequests/2", data)
      .then((response) => checkError(response))
      .then(() => {
        setAlertMsg("Reservation request denied successfully!");
        setAlertColor("info");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/requests", { replace: true });
        }, 2000);
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!");
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        logError(error);
      });
  };

  return (
    <div className="page-container">
      <h5>Available Room Categories</h5>
      <Alert color={alertColor} isOpen={showAlert}>{alertMsg}</Alert>
      {arrSearchItems.length > 0 ? (
        <ul className="two-column-list">
          {arrSearchItems.map(item => (
            <li key={item.room_category_id}>
              <div className="divRoomCategory">
                {roomCategories[item.room_category_id]} - 
                <input type="number" min="0" id={`txt${item.room_category_id}`} className="form-control txtRoomCategory" onChange={validateRequestCount} />
                / {item.available_rooms} rooms
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No rooms available</p>
      )}
      <div className="confirmation-section">

      <label className="custom-radio">
        Confirm
        <input type="radio" name="option" value="Confirm" onChange={processRequest} />
        <span className="radio-button confirm"></span>
      </label>

      <label className="custom-radio">
        Deny
        <input type="radio" name="option" value="Deny" onChange={processRequest} />
        <span className="radio-button deny"></span>
      </label>

{/* 
        <label>
          <input type="radio" name="confirmation" value="Confirm" onChange={processRequest} style={{marginRight:'0.5em'}}/>
          Confirm
        </label>
        <label>
          <input type="radio" name="confirmation" value="Deny" onChange={processRequest} style={{marginRight:'0.5em'}}/>
          Deny
        </label> */}

        <div className="send-email">
          <input type="checkbox" onChange={checkIfEmailToBeSent} style={{marginRight:'0.5em'}}/>
          Send email to guest 
        </div>
      </div>
      <div className="save-button-container">
        <button className="btnBig" onClick={saveRequest}>Save</button>
      </div>
    </div>
  );
}

export default ProcessRequest;
