import React, {useEffect} from 'react';

import ErrorBoundary from '../ErrorBoundary'

import { useLocation } from 'react-router-dom';    

import ReservationDetails from './ReservationDetails';  
import ProcessRequest from './ProcessRequest';

// core components
import Header from "./Header";
import { useNavigate } from "react-router-dom";

function ReservationContainer() {

    const location = useLocation();  

    const reservationItem = location.state?.reservationItem != null
    ? (sessionStorage.setItem("reservationItem", JSON.stringify(location.state.reservationItem)), location.state.reservationItem)
    : JSON.parse(sessionStorage.getItem("reservationItem") || "[]");

    const navigate = useNavigate(); 

    useEffect(() => {
        if (sessionStorage.getItem('authenticated') !== 'true'){
          navigate("/", { replace: true });
        }

        return () => {
            // This will run when the component unmounts
            sessionStorage.removeItem("reservationStatusId");
            sessionStorage.removeItem("reservationItem");        
          };

    }, []); 
    
          return (
            <>
             <Header selected="Reservations"/> 

            <div className="divError">    
            <ErrorBoundary>

            <div className="divPageContainer">
            <h3>Reservation Details</h3>   
            <div className = "div-table">
                    <div className = "div-table-row">
                          <div className ="div-table-col">
                                <ReservationDetails reservationDetail={JSON.parse(sessionStorage.getItem("reservationItem"))}></ReservationDetails>

                                {JSON.parse(sessionStorage.getItem("reservationItem")).rStatusId === 1 ? //New Request
                                  <ProcessRequest reservationDetail={JSON.parse(sessionStorage.getItem("reservationItem"))}></ProcessRequest> 
                                  : null
                                }

                          </div>
                          <br/>
                    </div>
              </div>
            </div>
            </ErrorBoundary>
            </div>
            </>
          );
}

export default ReservationContainer;
