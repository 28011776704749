import {React, useState, useEffect} from "react";

import { reservationStatuses, reservationTypes, sanskaras } from '../constants/roomAttributes.js';

import {fetch, destroy} from '../utils/httpUtil.js';   

import {checkError, logError} from '../utils/helpers.js';   

import moment from 'moment';

import { Row, Col } from 'reactstrap'; 

import { Link } from 'react-router-dom';     

import "../css/confirm-reservation.css";

import { confirmAlert } from 'react-confirm-alert';

import {Suspense, useRef } from 'react';   

import GuestDetails from './GuestDetails';
import AllocateRooms from './AllocateRooms';  
import RoomBookings from './RoomBookings';  
import Donations from './Donations';  

// import { notify } from 'react-notify-toast'; 

function ReservationDetails({ reservationDetail }) {

    const [refresh, setRefresh] = useState(false);   
    const [isCancelled, setIsCancelled] = useState(false);

    const receivedDonationRef = useRef(null);
 
    const [reservationDetails, setReservationDetails] = useState({

        rTypeId: reservationDetail.rTypeId, 
        rStatusId: sessionStorage.getItem('reservationStatusId') === null ? reservationDetail.rStatusId : sessionStorage.getItem('reservationStatusId'),  

        reservationId: reservationDetail.rId,
        guestId: reservationDetail.gId, 

        guestName: reservationDetail.guestName,  
        emailId: reservationDetail.emailId === null? null: reservationDetail.emailId, 
        phoneNo: reservationDetail.phoneNo === null? null: reservationDetail.phoneNo,
        referredBy: null,

        arrivalDate: null,
        departureDate: null,

        noOfPpl: null,
        userComments: null,
        sanskaraId: null,

        ta_first_name: null,    
        ta_last_name: null,
        company_name: null, 
        website: null,  
        email_id: null,

        singleRooms: null,
        doubleRooms: null,
        tripleRooms: null,
        isHallNeeded: null,
        hallDates: null
    });

    const typeClass = reservationTypes[reservationDetail.rTypeId].toLowerCase();

    const getReservationDetails = () => {
           fetch("apiReservations/" + reservationDetails.reservationId)
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {  

                setReservationDetails({ 
                    ...reservationDetails,
                    emailId: result[0].email_id === null? null: result[0].email_id,
                    phoneNo: result[0].phone_no === null? null: result[0].phone_no,
                    referredBy: result[0].referred_by === null? null: result[0].referred_by,    
                    arrivalDate: result[0].date_of_arrival,    
                    departureDate: result[0].date_of_departure,                   
                    noOfPpl: result[0].no_of_people,
                    userComments: result[0].user_comments,
                    sanskaraId: result[0].sanskara_id === null? null: result[0].sanskara_id,
                    ta_first_name: result[0].ta_first_name === null? null: result[0].ta_first_name,
                    ta_last_name: result[0].ta_last_name === null? null: result[0].ta_last_name,
                    company_name: result[0].company_name === null? null: result[0].ta_company_name,    
                    website: result[0].website === null? null: result[0].ta_website,   
                    email_id: result[0].email_id === null? null: result[0].ta_t_email_id,    
                    singleRooms: result[0].single_rooms_requested === null? 0: result[0].single_rooms_requested,
                    doubleRooms: result[0].double_rooms_requested === null? 0: result[0].double_rooms_requested,
                    tripleRooms: result[0].triple_rooms_requested === null? 0: result[0].triple_rooms_requested,
                    isHallNeeded: result[0].is_hall_needed === null? 0: result[0].is_hall_needed,
                    hallDates: result[0].hall_dates === null? null: result[0].hall_dates
                }); 

                document.getElementById("spReceivedDonation").innerHTML = result[0].donation_amount === null? 0: (result[0].donation_amount).toLocaleString('en-IN');

            })
            .catch((error) => {
                //notify.show('Oops! Something went wrong! Please try again!', 'error');
                logError(constructor.name + " " + error);
            });
    }

  useEffect(() => {
        sessionStorage.setItem('reservationStatusId', reservationDetail.rStatusId);      
        getReservationDetails();  
  }, []);


  useEffect(() => {
    if (refresh) {
        getReservationDetails();
        setRefresh(false); // Reset the refresh state
    }
  }, [refresh]);

    function showGuestDetails() {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="room-details">
                    <h3>Guest Details</h3>  
                    <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>  
                    <Suspense fallback={<div id="loader" class="loaderCenter"></div> }>           
                    <GuestDetails gId={reservationDetails.guestId} onClose={() => {onClose();}}></GuestDetails> 
                    </Suspense>
                </div>
              )}
          })
    }

    function updateDonationDetails(updatedAmt) {
        document.getElementById("spReceivedDonation").innerHTML = updatedAmt;   
    }

    function showDonationDetails() {

        const spReceivedDonation = document.getElementById("spReceivedDonation");
        const spExpectedDonation = document.getElementById("spExpectedDonation");

        const receivedDonation = parseInt(spReceivedDonation.innerHTML);
        const expectedDonation = parseInt(spExpectedDonation.innerHTML);

        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="divPageContainer" style={{margin:'auto', width:'auto'}}>
                    <h3>Donation Details</h3>  
                    <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>  
                    <Suspense fallback={<div id="loader" class="loaderCenter"></div> }>           
                        <Donations rId = {reservationDetails.reservationId} gId={reservationDetails.guestId}  onSave={(updatedAmt) => {updateDonationDetails(updatedAmt); onClose();}}></Donations> 
                    </Suspense>
                </div>
              )}
          })
    }

    function handleCancel(){
    
        confirmAlert({
          title: 'Confirm to cancel',
          message: 'Are you sure you want to cancel this reservation?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => cancelReservation(),
            },
            {
              label: 'No',
              onClick: () => false
            }
          ]
        })
    }

    function showCheckInForm(){
     
        const url = `/CheckInForm?gId=${reservationDetails.guestId}&rId=${reservationDetails.reservationId}`;
        window.open(url, '_blank', 'width=800,height=600');
    };
    
    function cancelReservation() {
        destroy("apiReservations/" + reservationDetails.reservationId + "?uId=" + sessionStorage.getItem('userId'))
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {  
                refreshReservation(result[0].reservation_status_id, true);  // Pass true to indicate cancellation
            })
            .catch((error) => {
                logError(error);
            });        
    }
    

    // function refreshReservation(rStatus){ 
    //     setReservationDetails({ ...reservationDetails, rStatusId: rStatus })
    //     sessionStorage.setItem('reservationStatusId', rStatus); 
    //     setRefresh(true);
    // }

    function refreshReservation(rStatus, cancelled = false) {  
        setReservationDetails({ ...reservationDetails, rStatusId: rStatus });
        sessionStorage.setItem('reservationStatusId', rStatus); 
        setIsCancelled(cancelled);  // Update the cancellation state
        setRefresh(true);
    }
    

    function updateDates(arrivalDate, departureDate){
        setReservationDetails({ ...reservationDetails, arrivalDate: arrivalDate, departureDate: departureDate })
    } 

    const statusClass = reservationStatuses[parseInt(reservationDetails.rStatusId)].toLowerCase().replace(/\s/g, '');
  
    return (
    <>
    <div className="page-container">

        <h2>
            {reservationDetails.guestName}
            <b className="bRef" style={{color:'green', fontSize:'12pt', marginLeft:'1em'}}>
                {reservationDetails.referredBy !== null? `c/o ${reservationDetails.referredBy}`: ''} 
            </b> 
            <span className={`reservation-item ${typeClass}`}>{reservationTypes[reservationDetails.rTypeId]} {sanskaras[reservationDetails.sanskaraId]}</span>   
            <span className={`reservation-item ${statusClass}`}>{reservationStatuses[parseInt(sessionStorage.getItem('reservationStatusId'))]}</span>
        </h2>

        <div className="divFloatRight" style={{marginTop:'-4em', visibility: sessionStorage.getItem('reservationStatusId') !== '1' ? 'visible': 'hidden', display: (sessionStorage.getItem('reservationStatusId') !== '1')? 'inline': 'none'}}> 
            
            <Link onClick={() => {showCheckInForm()}} style={{marginRight:'2em', textDecoration:'underline', fontWeight:'bold'}}>
            <img src={require("../img/printer.png")} alt="Print Check In Form" className="imgGuestIcons"></img>
            Check In Form</Link>

            {(parseInt(sessionStorage.getItem('reservationStatusId')) == 2 || 
            parseInt(sessionStorage.getItem('reservationStatusId')) == 3 || 
            parseInt(sessionStorage.getItem('reservationStatusId')) == 4) &&  
                <button className="btn btn-secondary" onClick={() => {handleCancel()}}>Cancel Reservation</button>
            }

        </div>
        <br/>

        <div class="content-container">
            <div class="left-section">
                <div class="div-container">
                    <div class="div-box">
                        <span class="spanBold dates">{moment(reservationDetails.arrivalDate).format('DD-MMM-YY')}</span>
                    </div>
                    <div class="div-box">
                        <span class="dates">to
                        </span>
                        </div>
                    <div class="div-box">
                        <span class="spanBold dates">{moment(reservationDetails.departureDate).format('DD-MMM-YY')}</span>
                    </div>
                </div>
                
                <div class="div-container">
                    <div class="div-box">
                        <span class="spanBold highlightNo highlightNo1">{reservationDetails.noOfPpl}</span>    
                        <span class="spanNormal">Guests</span>
                    </div>
                    <div class="separator"></div>
                    <div class="div-box">
                        <span class="spanBold highlightNo highlightNo2"><span id="spGrandBeds">0</span></span>
                        <span class="spanNormal">Beds</span>
                    </div>
                </div>
                <div class="div-container" style={{visibility: (reservationDetails.rTypeId === 2 || reservationDetails.rTypeId === 4 || reservationDetails.rTypeId === 6)? 'visible': 'hidden', display: (reservationDetails.rTypeId === 2 || reservationDetails.rTypeId === 4 || reservationDetails.rTypeId === 6)? 'inline': 'none'}}>
                        <span class="spanBold">{reservationDetails.singleRooms}</span>-
                        <span class="spanSimple spanSmall">Single</span>                   
                            &nbsp;                 
                        <span class="spanBold">{reservationDetails.doubleRooms}</span>-        
                        <span class="spanSimple spanSmall">Double</span>                
                            &nbsp;  
                        <span class="spanBold">{reservationDetails.tripleRooms}</span>-                  
                        <span class="spanSimple spanSmall">Triple</span>                
                </div>
                <div class="right-section" style={{visibility: sessionStorage.getItem('reservationStatusId') !== '1' ? 'visible': 'hidden', display: (sessionStorage.getItem('reservationStatusId') !== '1' )? 'inline': 'none'}}>
                    <div class="info-box">
                        <div class="info-item">
                        <h3 style={{margin:'0 0 0.5em 0'}}><img src={require("../img/user.png")} alt="Guest" className="imgGuestIcons" style={{height:'20px'}}></img>&nbsp;Guest</h3>
                            <span className="spanBold" style={{color:'#8b4513'}}>{reservationDetails.guestName}</span><br/>
                        </div>
                        <div class="info-item">
                            <img src={require("../img/email.png")} alt="Email ID" className="imgGuestIcons" style={{height:'20px'}}></img>&nbsp;
                            <span className="spanSmall">{reservationDetails.emailId !== null? reservationDetails.emailId: ' - '}</span><br/>
                        </div>
                        <div class="info-item">
                            <img src={require("../img/mobile.png")} alt="Phone Number" className="imgGuestIcons" style={{height:'20px'}}></img>
                            <span className="spanSmall">{reservationDetails.phoneNo}</span><br/>
                        </div>  
                        <div class="info-item">
                            <Link onClick={() => {showGuestDetails()}} className="btn btn-primary" style={{marginBottom:'-1em'}}>Edit</Link>
                        </div>  
                    </div>              
                </div>
                 <div class="right-section" style={{visibility: sessionStorage.getItem('reservationStatusId') !== '1' ? 'visible': 'hidden', display: (sessionStorage.getItem('reservationStatusId') !== '1')? 'inline': 'none'}}>
                    <div class="info-box">
                        <div class="info-item">                            
                            <h3 style={{margin:'0 0 0.5em 0'}}><img src={require("../img/donation.png")} alt="Donation" className="imgGuestIcons" style={{height:'20px'}}></img>&nbsp;Donations</h3>
                            <span className="spanBold">&#8377;&nbsp;
                                <span id="spReceivedDonation" ref={receivedDonationRef}>0</span>
                                / &#8377;&nbsp;
                                <span id="spExpectedDonation">0</span>
                            </span>
                            <br/>
                        </div>
                        <div class="info-item">
                            <Link onClick={() => {showDonationDetails()}} className="btn btn-primary" style={{marginBottom:'-1em'}}>Add</Link>
                        </div>  
                    </div>              
                </div>
            </div>                  
            <div class="center-section">
                <div class="info-box">
                    <div class="info-item">
                        <span><b><i>{reservationDetails.userComments}</i></b></span>
                    </div>
                    <div style={{visibility: (reservationDetails.rTypeId === 4)? 'visible': 'hidden', display: (reservationDetails.rTypeId === 4)? 'inline': 'none'}}>
                        <Row>
                            <Col md={5}>

                        <div class="info-item">
                            <label style={{width:'150px'}}>Travel Agent Name:</label>
                            <span>{reservationDetails.ta_first_name + " " + reservationDetails.ta_last_name}</span> 
                        </div>
                        </Col>  
                        <Col md={5}>
                        <div class="info-item">
                            <label>Company Name:</label>
                            <span>{reservationDetails.company_name}</span>
                        </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col md={5}>
                        <div class="info-item">
                            <label>Website:</label>
                            <span>{reservationDetails.website}</span>
                        </div>
                        </Col>
                        <Col md={5}>
                        <div class="info-item">
                            <label>Email ID:</label>
                            <span>{reservationDetails.email_id}</span>
                        </div>
                        </Col>
                        </Row>
                    </div>
                    <div style={{visibility: (reservationDetails.rTypeId == 2 || reservationDetails.rTypeId === 6)? 'visible': 'hidden', display: (reservationDetails.rTypeId === 2 || reservationDetails.rTypeId === 6)? 'inline': 'none'}}>
                        <Row>
                            <Col md={5}>
                        <div class="info-item">
                            <label>Hall Needed?:</label>
                            <span>{reservationDetails.isHallNeeded === 1? 'Yes' : 'No'}</span>
                        </div>
                        </Col>  
                        <Col md={5}>
                        <div class="info-item">
                            <label>Dates:</label>
                            <span>{reservationDetails.hallDates}</span>
                        </div>
                        </Col>
                        </Row>
                    </div>
                </div>  
                {(sessionStorage.getItem('reservationStatusId') === '2' &&  reservationDetails.noOfPpl !== null &&
                 // Confirmed Status
                    <AllocateRooms reservationDetail={reservationDetails} refreshReservation={refreshReservation}></AllocateRooms> 
                )} 

                {(parseInt(sessionStorage.getItem('reservationStatusId')) >= 3 && reservationDetails.noOfPpl !== null &&
                 // Allocated and above Statuses
                    <RoomBookings reservationDetail={reservationDetails} refreshReservation={refreshReservation} 
                    receivedDonationRef={receivedDonationRef} updateDates={(arrivalDate, departureDate) => updateDates(arrivalDate, departureDate)} 
                    isCancelled={isCancelled}></RoomBookings> 
                )}         
                

            </div> 
                
        </div>
    </div>
    </>
    );    
}

export default ReservationDetails;
