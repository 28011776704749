import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Alert } from 'reactstrap';
import { fetch } from '../utils/httpUtil.js';
import { logError, checkError } from '../utils/helpers';
import { countries } from '../constants/countries.js';
import { useLocation } from 'react-router-dom';

function CheckInForm() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const [reservationDetails, setReservationDetails] = useState({
    guestId: query.get('gId'), 
    reservationId: query.get('rId') 
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const fetchFormDetails = () => {
    fetch("apiGuests/" + reservationDetails.guestId)
      .then((response) => checkError(response))
      .then((result) => {
        console.log(result[0]);

        setReservationDetails((prevDetails) => ({
          ...prevDetails,
          firstName: result[0].first_name,  
          lastName: result[0].last_name,
          reference: result[0].referred_by, 
          idNumber: result[0].id_number,
          address: result[0].address,
          city: result[0].city,
          state: result[0].state,
          countryId: result[0].country_id,
          zipCode: result[0].zip_code,
          phoneNo: result[0].phone_no,
          emailId: result[0].email_id,
          eFirstName: result[0].e_first_name,
          eLastName: result[0].e_last_name,
          ePhoneNo: result[0].e_phone_no,
          eRelationship: result[0].e_relationship
        }));

        return fetch("apiRoombookings/" + reservationDetails.reservationId + "?prnt=1");
      })
      .then((response) => checkError(response))
      .then((roomResults) => {
        console.log(roomResults[0]);
        setReservationDetails((prevDetails) => ({
          ...prevDetails,
          roomNos: roomResults[0].room_nos, 
          noOfPpl: roomResults[0].no_of_people, 
          userComments: roomResults[0].user_comments,
          dateOfArrival: roomResults[0].date_of_arrival,
          dateOfDeparture: roomResults[0].date_of_departure
        }));
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!");
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        logError(`CheckInForm ${error}`);
      });
  };

  useEffect(() => { 
    fetchFormDetails();
    setTimeout(() => {  
      window.print();
    }, 500);
  }, []);

  return (
    <div id="divReservationFormContents" style={{width:'100%'}}>
      <div style={{fontFamily: 'Calibri', fontSize:'12pt', width:'100%', minHeight:'100vh', overflow:'hidden', display:'block', position:'relative', paddingBottom: '0'}}>
        <h4 style={{margin: 0, textAlign: 'center'}}>SWAMI SHUKDEVANAND TRUST</h4>
        <h4 style={{margin: 0, textAlign: 'center'}}>Parmarth Niketan</h4><br/>
        <h3 style={{margin: 0, textAlign: 'center'}}><u>CHECK IN FORM No. {reservationDetails.reservationId}</u></h3>

        <Alert color={alertColor} isOpen={showAlert}> {alertMsg} </Alert> 

        <div id="divReservationForm" style={{marginTop:'1em', padding:'0.5em'}}>
          <table cellPadding="7" style={{width: '99.5%', textAlign:'left', borderCollapse: 'collapse', border: '1px solid black'}}>
            <tbody>
              <tr>
                <th style={{width:'35%'}}>
                  Guest Information
                </th>
                <th style={{width:'35%'}}>
                  Visit Information
                </th>
                <th style={{width:'25%'}}>
                  Emergency Contact
                </th>
              </tr>
              <tr>
                <td style={{width:'35%', verticalAlign:'top'}}>
                  <b>{reservationDetails.firstName} {reservationDetails.lastName} </b>
         
                  {reservationDetails.reference ? <span style={{color:'green'}}>c/o {reservationDetails.reference}</span> : ''} <br/>
                
                  {reservationDetails.address} <br/>
                  {reservationDetails.city} <br/>
                  {reservationDetails.state} <br/>
                  {countries[reservationDetails.countryId]} &nbsp;
                  {reservationDetails.zipCode}
                </td>
                <td style={{width:'35%', verticalAlign:'top'}}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{width:'50%'}}>Check In:</td>
                        <td><b>{moment(reservationDetails.dateOfArrival).format('DD-MMM-YYYY')}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:'50%'}}>Check Out:</td>
                        <td><b>{moment(reservationDetails.dateOfDeparture).format('DD-MMM-YYYY')}</b></td>
                      </tr>
                      <tr>
                        <td style={{width:'50%'}}># of Guests:</td>
                        <td>{reservationDetails.noOfPpl}</td>
                      </tr>
                      <tr>
                        <td style={{width:'50%'}}>Purpose:</td>
                        <td>{reservationDetails.userComments}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td style={{width:'25%', verticalAlign:'top'}}>
                  {reservationDetails.eFirstName} {reservationDetails.eLastName} <br/>
                  {reservationDetails.ePhoneNo} <br/>
                  {reservationDetails.eRelationship} <br/>
                  <b>ID No.:</b>{reservationDetails.idNumber} 
                  <br/>
                  <b>Vehicle No:</b>
                </td>
              </tr>
            </tbody>
          </table>
          <br/>
          <div>
            <table style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td>
                    <span style={{fontWeight: 'bolder'}}>Guest(s)</span>
                  </td>
                  <td style={{width:'75%', paddingRight:'1.5em', textAlign: 'right'}}>
                    Male: ____ Female: ____ Children: _____
                  </td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table cellPadding="6" style={{width: '99%', textAlign:'left', borderCollapse: 'collapse', border: '1px solid black'}}>
              <tbody>
                <tr style={{border: '1px solid black'}}>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Relation</th>
                  <th>Phone/Email</th>                          
                  <th>Medical Condition</th>                                                  
                </tr>
                <tr>
                  <td>{reservationDetails.firstName} {reservationDetails.lastName}</td>
                  <td></td>
                  <td>PRIMARY</td>
                  <td>{reservationDetails.phoneNo} / 
                  {reservationDetails.emailId}</td>
                  <td></td>                                                  
                </tr>                                        
              </tbody>
            </table>
            <br/>
            <div><b>Room No(s):</b> {reservationDetails.roomNos}</div>
          </div>
        </div>
          
        <div id="divFooter" style={{position: 'absolute', bottom: '0', width:'100%'}}>
          <b>I hereby declare the above information as accurate and assure you we have gone over the rules and regulations of the ashram mentioned on your website/email and shall abide by the same.</b>
          <br/><br/>
          Signature:
          <br/><br/>
          <hr/>
          <i>For Office Use Only</i><br/>
          Receipt No: __________<br/>
          Checked In By:
        </div>
      </div>     
    </div> 
  );
}

export default CheckInForm;
