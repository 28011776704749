import React, { useEffect, useState, useRef } from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';   
import { fetch, store } from '../utils/httpUtil.js';
import { checkError, logError, createRoomsString } from '../utils/helpers.js';
import { blocks, floors, roomStatuses } from '../constants/roomAttributes.js';

function URooms() {
  const [groupedItems, setGroupedItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const printRef = useRef();

  const statusClasses = {
    1: 'autoassigned',
    2: 'allocated',
    3: 'checkedin',
    4: 'checkedout',
    5: 'housekeepingcompleted',
    6: 'cancelled',
    7: 'noshow',
  };

  function fetchUncleanRooms() {
    fetch('apiUrooms')
      .then(response => {
        return checkError(response);
      })
      .then(result => {
        const grouped = result.reduce((acc, item) => {
          if (!acc[item.block_id]) {
            acc[item.block_id] = [];
          }
          acc[item.block_id].push(item);
          return acc;
        }, {});

        setGroupedItems(grouped);
      })
      .catch(error => {
        logError(this.constructor.name + ' ' + error);
      });
  }

  useEffect(() => {
    fetchUncleanRooms();
  }, []);

  // blocks check box click
  function blocksChanged() {
    // select or de select child rooms
    var checkboxes = document.getElementsByName('uncleanBlocks');

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        var roomCheckedCheckBoxes = checkboxes[i].parentElement.lastChild.getElementsByTagName('input');
        for (var x = 0; x < roomCheckedCheckBoxes.length; x++) {
          roomCheckedCheckBoxes[x].checked = true;
        }
      } else {
        var roomUncheckedCheckBoxes = checkboxes[i].parentElement.lastChild.getElementsByTagName('input');
        for (var y = 0; x < roomUncheckedCheckBoxes.length; y++) {
          roomUncheckedCheckBoxes[y].checked = false;
        }
      }
    }
  }

  // Done button click
  function handleUncleanRoom() {
    var selectedRooms = getAllSelectedRooms();
    var str_rooms = createRoomsString(selectedRooms);
    console.log('Rooms String: ', str_rooms);

    if (str_rooms !== '') {
      const payload = {
        str_room_booking_ids: str_rooms,
      };

      store('apiUrooms/', JSON.stringify(payload))
        .then(response => {
          return checkError(response);
        })
        .then(result => {
          setAlertMsg('Room(s) marked as cleaned successfully.');
          setAlertColor('success');
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        })
        .catch(error => {
          setAlertMsg('Oops! Something went wrong! Please try again!');
          setAlertColor('danger');
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
          logError(error);
        });

      updateUncleanRoomsState(selectedRooms);
    }
  }

  function getAllSelectedRooms() {
    // rooms
    var selectedRooms = [];
    var checkboxes = document.getElementsByName('uncleanRooms');

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        console.log(checkboxes[i].value);
        selectedRooms.push(checkboxes[i].value);
      }
    }
    return selectedRooms;
  }

  function updateUncleanRoomsState(selectedRooms) {
    const updatedGroupedItems = { ...groupedItems };

    selectedRooms.forEach(roomId => {
      Object.keys(updatedGroupedItems).forEach(blockId => {
        if (updatedGroupedItems[blockId].find(booking => booking.room_booking_id === Number(roomId))) {
          updatedGroupedItems[blockId] = updatedGroupedItems[blockId].filter(booking => booking.room_booking_id !== Number(roomId));
        }
      });
    });
    setGroupedItems(updatedGroupedItems);
  }

  const nonEmptyBlocks = Object.keys(groupedItems).filter(blockId => groupedItems[blockId].length > 0);

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.title = 'Open Housekeeping - Rooms to Clean - ' + new Date().toLocaleString();  
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page to restore the original content
  };

  if (nonEmptyBlocks.length === 0) {
    return (
      <>
        <div className="divSectionHeader">
          <h4>Open Housekeeping</h4>
          <hr />
          No rooms to clean!
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="divSectionHeader">
          <h4>Open Housekeeping</h4>
          <hr />
          <div className="save-button-container" style={{ marginBottom: '-2.5em' }}>
            <Link 
              onClick={() => { handlePrint() }} alt="Print" 
              style={{ marginTop: '-0.5em', marginRight: '2em', textDecoration: 'underline', fontWeight: 'bold' }}>
              Print
            </Link>

            <button className="btnBig" style={{ marginTop: '-0.5em' }} onClick={handleUncleanRoom}>Done</button>
          </div>
          <Alert isOpen={showAlert} color={alertColor} fade={true}>
            {alertMsg}
          </Alert>
          <div className="divList" ref={printRef}>
            <ul style={{ listStyleType: 'disc', padding: '0 0 0 2em', fontSize: '12pt' }}>
              {nonEmptyBlocks.map(blockId => (
                <li key={blockId} style={{ padding: '0px', borderBottom: 'none', height: '100%', marginBottom: '1em' }}>
                  <input type="checkbox" name="uncleanBlocks" onClick={blocksChanged} value={blockId} />
                  {blocks[blockId]}
                  <ol style={{ fontSize: '10pt' }}>
                    {groupedItems[blockId].map(booking => {
                      const statusClass = statusClasses[booking.room_status_id];
                      return (
                        <li key={booking.room_booking_id} style={{ padding: '0px', borderBottom: 'none', height: '100%', margin: '0.2em 0 0.2em 0' }}>
                          <span>
                            <input type="checkbox" name="uncleanRooms" id={booking.block_id} value={booking.room_booking_id} />
                            {booking.room_no}, {floors[booking.floor_no]} - <span className={`room-status ${statusClass}`}>{roomStatuses[booking.room_status_id]}</span>
                          </span>
                        </li>
                      );
                    })}
                  </ol>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default URooms;
