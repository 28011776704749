export const blocks = {
    1: 'Alaknanda',
    2: 'Ganga',
    3: 'Ganga Darshan',
    4: 'Himalaya Darshan',
    5: 'Gomti',
    6: 'Saraswati',
    7: 'Yamuna',
    8: 'Yoga Flats',
    9: 'Yoga Village',
    10: 'Saraswati New',
    11: 'Wait List',
    12: 'Kaveri',
    13: 'Event Halls'
  };

  export const floors = {
    0: 'GF',
    1: 'FF',
    2: 'SF',
    3: 'TF'
  };

  export const reservationTypes = {
    1: 'General',
    2: 'Retreat',
    3: 'Sanskara',
    4: 'Agent',
    5: 'Course',
    6: 'Katha',
    7: 'Sevak'
  };

  export const arrReservationTypes = ['General', 'Retreat', 'Sanskara', 'Agent', 'Course', 'Katha', 'Sevak']

  export const sanskaras = {
    1: 'Mundan',
    2: 'Yagno Pavit',
    3: 'Marriage',
    4: 'Ash Immersion',
    5: 'Special Pooja'
  };

  export const roomCategories = {
    1: '1-bed',
    2: '2-beds',
    3: '3-beds',
    4: '4-beds',
    5: '5-beds',
    6: '6-beds',
    7: '7-beds',
    8: '8-beds',
    9: '9-beds',
    10: '10-beds',
    11: '11-beds',
    12: '12-beds',
    13: '13-beds',
    14: '14-beds',
    15: '15-beds',
    16: '16-beds'
  };



  export const reservationStatuses = {
    1: "New",
    2: "Confirmed",
    3: "Allocated",
    4: "Checked In",
    5: "Checked Out",
    6: "Cancelled",
    7: "No Show",
    8: "Denied"
  };

  export const roomStatuses = { 
    1: "Auto Assigned",
    2: "Allocated",
    3: "Checked In",  
    4: "Checked Out",
    5: "Housekeeping Completed",
    6: "Cancelled",
    7: "No Show"
  };

  // export const eventHalls = { 
  //   1: "Yoga Hall - Ground Floor",
  //   2: "Yoga Hall - 1st Floor",
  //   3: "Yoga Hall - 2nd Floor",
  //   4: "New Yoga Hall",
  //   5: "Saraswati Garden",
  //   6: "Ghat Pandal",
  //   7: "Satsang Hall",
  //   8: "Samadhi Mandir",
  //   9: "Event Garden",
  //   10: "Ghat Havan Kund",
  //   11: "Navagraha Mandir Area",
  //   12: "New Saraswati Garden"
  // };  



