import {React, useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import {fetch} from '../utils/httpUtil.js';
import {checkError, logError} from '../utils/helpers.js';
import {reservationTypes} from '../constants/roomAttributes.js'; 

import {Alert} from 'reactstrap'; 

function RequestsCount() {

  const navigate = useNavigate();

  const [countItems, setCountItems] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();

  useEffect(() => {
    if (sessionStorage.getItem('authenticated') === 'true'){
      fetchRequestsCount();
    } else {
      navigate("/", { replace: true });
    }
  }, true);

  function fetchRequestsCount() {
    return fetch("apiRequests/1")
    .then((response) => {
        return checkError(response);
    })
    .then((result) => {    
      setCountItems(result); 
    })
    .catch((error) => {
      setAlertMsg("Oops! Something went wrong! Please try again!")
      setAlertColor("danger");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000); 
      logError(error);
    });
  }

  return (
    <>
      <div className="divSectionHeader"><h4>Requests Count</h4>
            <hr />  
            <Alert color={alertColor} isOpen={showAlert}>
              {alertMsg}  
              </Alert>  
              <div className="div-table-today">
             <ul>
                 {countItems.map(item => (
                  <li key={item.reservation_type_id} style={{width:'100px'}}>
                    <span className="spCount">{item.cnt}</span><br/>
                   <span className="spNameCount"> {reservationTypes[item.reservation_type_id]} </span>
                  </li>
                ))}
             </ul>
             </div>            
        </div>
    </>
  );
}

export default RequestsCount;