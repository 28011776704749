import React, { useState, useEffect } from 'react';

import ErrorBoundary from '../ErrorBoundary'  
import Header from './Header';

import { useNavigate } from 'react-router-dom'; 

import {blocks} from '../constants/roomAttributes';

import {Suspense} from 'react';

import moment from 'moment';  

import {logError, checkError} from '../utils/helpers';
import {fetch} from '../utils/httpUtil';
import { confirmAlert } from 'react-confirm-alert'; 

import "../css/table.css"; 

import { Row, Col, FormGroup, Label, Input, Alert } from 'reactstrap'; 

import RoomDetails from './RoomDetails';


function Rooms(){

    const navigate = useNavigate(); 
    const [blockItems, setBlockItems] = useState([]); 
    const [roomItems, setRoomItems] = useState([]); 
    const [isLoaded, setIsLoaded] = useState(false);  
    const [selectedBlock, setSelectedBlock] = useState('0');

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState(); 

    useEffect(() => {         
          if (sessionStorage.getItem('authenticated') === 'true'){
              for (let i = 1; i <= 13; i++) {
                setBlockItems(prevBlockItems => [...prevBlockItems, blocks[i]]);
              } 
          } else {
              navigate("/", { replace: true });
          } 
    }, []); 
          

    function handleBlockChange(event){
      setSelectedBlock(event.target.value);
      getRoomDetails();
    }

    function getRoomDetails(){
        if (document.getElementById("slBlocks").value === "0"){
          setIsLoaded(false); 
        } else {
            fetch("apiRooms/?bId=" + document.getElementById("slBlocks").value)
                .then((response) => {
                return checkError(response);
                })
                .then((result) => {
                  setIsLoaded(true);  
                  setRoomItems(result); 
                    })
                .catch((error) => {
                  setAlertMsg("Oops! Something went wrong! Please try again!")
                  setAlertColor("danger");
                  setShowAlert(true);
                  setTimeout(() => {
                    setShowAlert(false);
                  }, 2000); 
                  logError(error);
                });
        }
    }

    function handleUpdateRoom(roomId){

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="room-details">
                <h3>Room Details</h3>  
                <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>  
                <Suspense fallback={<div id="loader" class="loaderCenter"></div> }>           
                <RoomDetails rId={roomId} onSave={() => {getRoomDetails(); onClose();}} handleBlockChange={() => handleBlockChange}></RoomDetails>                 
                </Suspense>
            </div>
          )}
      })
    }

    function showFutureBookings(roomId, roomNo){
      fetch("apiRooms/" + roomId + "?type=2")
            .then((response) => {
              return checkError(response);
            })
            .then((result) => {
              loadRoomFutureBookings(roomNo, result);
            })
            .catch((error) => {
              setAlertMsg("Oops! Something went wrong! Please try again!")  
              setAlertColor("danger");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000);

              logError(error);
            });
    }

  function loadRoomFutureBookings(roomNo, results){

      confirmAlert({
          customUI: ({ onClose }) => {
            return (

              <div className = "div-table" style={{padding:'2em'}}>  
                        <div className="divSectionHeader" style={{marginBottom:'1em'}}> 
                          <h4>Room Future Bookings - {roomNo}</h4>  
                          <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>
                        </div>

                        <table className="simple-table" style={{width:'auto'}}>
                          <thead>
                            <tr>
                              <th style={{width: '2%'}}>#</th>
                              <th style={{width: '15%'}}>Arrival Date</th>
                              <th style={{width: '15%'}}>Departure Date</th>
                              <th style={{width: '25%'}}>Guest Name</th>
                              <th style={{width: '20%'}}>Guest Email / Phone</th>  
                            </tr>
                          </thead>
                          <tbody>
                            {results.length > 0 ? (
                              results.map((item, index) => (
                                <tr key={index}>
                                  <td style={{width: '2%', cursor:'none'}}>{index + 1}</td>
                                  <td style={{width: '15%', cursor:'none'}}>{moment(item.date_of_arrival).format('DD-MMM-YY')}</td>
                                  <td style={{width: '15%', cursor:'none'}}>{moment(item.date_of_departure).format('DD-MMM-YY')}</td>
                                  <td style={{width: '25%', cursor:'none'}}>{item.guest_name}</td>
                                  <td style={{width: '20%', cursor:'none'}}>{item.email_id != null ? item.email_id : item.phone_no}</td>  
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  <div id="divNoFutureBookings">No room bookings yet.</div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                      </div>  
                  
            )}  
          })
  }

    return (  
        <>
          <Header selected="Rooms"/>

          <div className="divError">    
          <ErrorBoundary>
              <div className="divPageContainer">
                    <h3>Rooms</h3> 
                    <hr/>
                    <Alert color={alertColor} isOpen={showAlert}>
                      {alertMsg}
                    </Alert>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label className="form-label">
                                    Blocks:
                                </Label>
                                <Input type="select" id="slBlocks" required className="form-control" value = {selectedBlock}
                                    onChange={(e) => handleBlockChange(e)}> 
                                    <option value="0">Please select</option>    
                                    {blockItems.length > 0 && blockItems.map((item, index) => ( 
                                        <option key={index+1} value={index+1}>{item}</option> 
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <br/><br/><br/>  
                    </Row>
                    <Row>
                    <Col md={12} style={{marginLeft:'-1.2em', backgroundColor:'unset'}}>
                          <div id="divRoomBookings" style={{ visibility: isLoaded? 'visible':'hidden', display: isLoaded? 'inline':'none' }} className="table-container">                      
                          <div className = "div-table">
                              <div className = "div-table-row">
                                  <div className ="div-table-col">    
                                        <div className="divSectionHeader"> 
                                            
                                                  {selectedBlock !== '0' && (
                                                    <>
                                                      <h4>Rooms in {blocks[selectedBlock]}</h4>
                                                      <hr/> 
                                                    </> 
                                                  )}                                    
                                                                  
                                                  <table className="simple-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Room No.</th>
                                                            <th>Notes</th>
                                                            <th>Status</th>
                                                            <th>Future Bookings</th>  
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {roomItems.map((item, index) => {
                                                          const availabilityClass = item.is_available? 'available': 'blocked';  
                                                              return (
                                                                <tr key={Math.random()} onClick={() => handleUpdateRoom(item.room_id)} className="selectable-row">
                                                                    <td>{index+1}</td>
                                                                    <td>{item.room_no}</td>
                                                                    <td>{item.notes}</td>
                                                                    <td><span className={`room-availability ${availabilityClass}`}>{item.is_available? 'Available': 'Blocked'}</span></td>
                                                                    <td><a onClick={(e) => {showFutureBookings(item.room_id, item.room_no)}} href="#">View</a></td>  
                                                                </tr>
                                                              );  
                                                        })} 
                                                    </tbody>
                                                  </table>   

                                          </div>  
                                  </div>  
                              </div> 
                          </div>  
                  </div> 
              </Col>  
            </Row>
              </div>
            </ErrorBoundary>
          </div>
        </>
    );
}

export default Rooms;