import React, { useEffect, useState } from 'react';

import {reservationTypes} from '../constants/roomAttributes';
import {fetch} from '../utils/httpUtil.js';
import {checkError, logError} from '../utils/helpers.js';
import {Alert} from 'reactstrap';

import { useNavigate } from "react-router-dom";

function CheckIns() {

      const navigate = useNavigate();

      const [checkInItems, setCheckInItems] = useState([]);   

      const [showAlert, setShowAlert] = useState(false);
      const [alertColor, setAlertColor] = useState();
      const [alertMsg, setAlertMsg] = useState();

      useEffect(() => {
          fetchCheckInDetails();
      }, []);

      function fetchCheckInDetails() {        
        fetch("apiCheckins")
          .then((response) => {
            return checkError(response);
          })
          .then((result) => {
              setCheckInItems(result); 
            })
            .catch((error) => {
              setAlertMsg("Oops! Something went wrong! Please try again!")
              setAlertColor("danger");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
              }, 2000); 
              logError(error);
            });
      }

      function showReservationDetails(rTypeID, rID, rStatusID, gID, guestName) {

        const reservationItem = {
          rTypeId: rTypeID, 
          rStatusId : rStatusID, //could be auto assigned or allocated
          rId: rID, 
          gId: gID, 
          guestName: guestName
        }

        navigate("/reservationContainer", { state: { reservationItem } });
      }

  if (checkInItems.length === 0) {
      return (
        <>
          <div className="divSectionHeader"><h4>Open Check Ins</h4>
          <hr />  
          No checkins!
          </div>  
        </>
        );
  }
  else {
      return (
        <>
          <div className="divSectionHeader"><h4>Open Check Ins</h4>
            <hr />
            <Alert color={alertColor} isOpen={showAlert}>
              {alertMsg}
            </Alert>
              <div className="divList">
                  <ol>    
                        {checkInItems.map(item => {   
                            const typeClass = reservationTypes[item.reservation_type_id].toLowerCase();
                            return (
                                <li key={item.id}>
                                  <span className="spGuestItem" onClick={() => showReservationDetails(item.reservation_type_id, item.reservation_id, item.reservation_status_id,
                                      item.guest_id, item.guest_name)}>
                                    <span className={`reservation-item ${typeClass}`}>
                                      {reservationTypes[item.reservation_type_id]}
                                    </span>
                                    {' - '}
                                    <b style={{color:'#8b4513'}}>{item.guest_name}</b>
                                    {item.referred_by == null ? '' : ` c/o `}<b className="bRef" style={{color:'green'}}>{item.referred_by}</b>      
                                    &nbsp;
                                    {' - '}{item.arrival_time}
                                    {item.is_delayed === 1 ? <span className="spDelayed" style={{color:'red', paddingLeft:'1em', fontVariant:'petite-caps', fontWeight:'bold'}}>(Overdue)</span> : ''}                                 
                                  </span>
                                  <div className="block-rooms" dangerouslySetInnerHTML={{ __html: item.block_rooms }}></div>
                                </li>
                              );                             
                        })}                     
                  </ol>                    
              </div>
          </div>
          </>
      );
  }
}
export default CheckIns;