import React, { useState, useEffect, Suspense } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../css/table.css";
import moment from 'moment';
import { Alert, Row, Col } from 'reactstrap';
import Header from './Header';

import { fetch } from '../utils/httpUtil';
import { checkError, logError } from '../utils/helpers';
import { blocks } from '../constants/roomAttributes';
import { confirmAlert } from 'react-confirm-alert';

const localizer = momentLocalizer(moment);

function AvailabilityCalendar() {
    const [events, setEvents] = useState([]);   
    const [eventDetails, setEventDetails] = useState([]);   
    const [alertMsg, setAlertMsg] = useState('');
    const [alertColor, setAlertColor] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    function getFormattedDate(dt) {
        return moment(dt).format('YYYY-MM-DD');
    }

    useEffect(() => {   
        const startDate = getFormattedDate(moment().startOf('month'));
        loadRoomAvailability(startDate);
    }, []);

    useEffect(() => {
    }, [events]);

    function handleMonthChange(dateSelected){
        if (moment(dateSelected).isBefore(moment(), 'month')) {
            setAlertMsg("No data available for earlier than current month");
            setAlertColor("warning");
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
        } else {
            const startDate = getFormattedDate(moment(dateSelected).startOf('month'));
            loadRoomAvailability(startDate);
        }
    }

    function loadRoomAvailability(startDate) {
        fetch("apiArooms/2?sdate=" + startDate)
            .then((response) => checkError(response))
            .then((result) => {
                const arrEvents = result.map((item) => ({
                    title: item.no_of_rooms === null ? "-" : item.no_of_rooms,
                    start: new Date(item.day_of_month),  // Ensure date format
                    end: new Date(item.day_of_month)     // Ensure date format
                }));
                setEvents(arrEvents); 
            })
            .catch((error) => logError(error));
    }

    function handleEventDateSelected(eventSelected) {
        if (eventSelected.title === "-") {
            return;
        } else {
            const startDate = getFormattedDate(eventSelected.end);
            fetch("apiArooms/3?sdate=" + startDate)
                .then((response) => checkError(response))
                .then((result) => {
                    setEventDetails(result);
                    showEventDetails(startDate, result);
                })
                .catch((error) => logError(error));
        }
    }

    function printAvailability(startDate) {  
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>');
        printWindow.document.write('Room Availability Details for ');
        printWindow.document.write(startDate);  
        printWindow.document.write('</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(document.getElementsByClassName('table-container')[0].innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    }

    function showEventDetails(startDate, eventDetails) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="room-details" id="divRoomDetails">
                        <h3>Room Availability Details for {startDate}</h3>
                        <img alt="..." src={require("../img/close.png")} className="imgClose" onClick={onClose}></img>
                        <Suspense fallback={<div id="loader" className="loaderCenter"></div>}>
                            {eventDetails.length > 0 ? (
                                <>
                                <div className="divPrintIcon">
                                    <img alt="Print" src={require("../img/printer.png")} onClick={() => printAvailability(startDate)}></img>  
                                </div>  
                                <div className="table-container">
                                    <table className="simple-table">
                                        <thead>
                                            <tr>
                                                <th>Blocks</th>
                                                <th># Available Rooms</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {eventDetails.map((item) => (
                                                <tr key={item.block_id}>
                                                    <td>{blocks[item.block_id]}</td>
                                                    <td>{item.no_of_rooms}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td><b>Total</b></td>
                                                <td><b>{eventDetails.reduce((acc, item) => acc + item.no_of_rooms, 0)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </>
                            ) : null}
                        </Suspense>
                    </div>
                );
            }
        });
    }

    function eventStyleGetter(event) {
        const style = {};
        if (parseInt(event.title) <= 0 || event.title === "-") {
            style.backgroundColor = 'darkgrey';
            style.width = '25%';
            style.textAlign = 'center'; 
        } else {
            style.backgroundColor = '#44953f';
            style.width = '25%';
            style.textAlign = 'center'; 
        }
        return { style };
    }

    return (
        <>
            <Header selected="Reservations" />
            <div className="divError">
                <div className="divPageContainer">
                    <h3>Availability Calendar</h3>
                    <hr/>
                    <Alert color={alertColor} isOpen={showAlert}>
                        {alertMsg}
                    </Alert>
                    <Row style={{marginLeft:'-0.1em'}}>
                        <Col md={12}>
                            <div style={{ height: '400px' }}>
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    views={['month']}
                                    onNavigate={handleMonthChange}
                                    onSelectEvent={handleEventDateSelected}
                                    eventPropGetter={eventStyleGetter}
                                    defaultView="month"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AvailabilityCalendar;
