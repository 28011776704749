import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

import ErrorBoundary from '../ErrorBoundary'
import Header from './Header';  

import {FormGroup, Label, Input } from 'reactstrap';

import TodayAvailability from './TodayAvailability';
import CheckIns from './CheckIns';
import CheckOuts from './CheckOuts';
import URooms from './URooms';



import "../css/dashboard.css";

function Dashboard() {

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');

    function handleReservationSearch() {
          navigate("/reservations", { state: {searchText } }); 
    }

    function handleSearchInputChange(event) {
        setSearchText(event.target.value);
    } 

    function handleKeyUp(event){
        event.preventDefault();
        if (event.keyCode === 13) {
           handleReservationSearch();
        }
    }

    return (
        <>
            <Header selected="Requests"/>

            <div className="divError">    
            <ErrorBoundary>
            <div className="divPageContainer">
            <h3>Today's Dashboard   
            <div className="divFloatRight" style={{width:'25%'}}>
                <FormGroup>
                    <Input type="text" value = {searchText}   
                        style={{backgroundColor:'white', fontSize:'12pt'}}
                        onChange={handleSearchInputChange}  
                        autoComplete="off"
                        placeholder="Search by email id or phone"
                        onKeyUp={(event) => handleKeyUp(event)}                         
                        className="form-control email-search"/>
                        <div className="button-holder" style={{float:'right', marginTop:'-1.5em', height:'25px', marginRight: '0.5em'}}>
                            <img id="imgSearch" src={require("../img/magnifying_glass.png")} alt="Print" style={{height:'25px', width:'25px'}} onClick={() => handleReservationSearch()}></img>
                        </div>
                </FormGroup>                                   
            </div>
            </h3>
            <hr/>
                    <div className = "div-table">
                            <div className = "div-table-row">
                                <div className ="div-table-col" style={{width: '100%', height: 'fit-content'}}>
                                    <TodayAvailability />
                                </div>
                            </div>  
                            <div className = "div-table-row" style={{display: 'flex', gap:'20px'}}>
                                <div className ="div-table-col" style={{width: '35%', height: 'fit-content'}}>
                                    <CheckIns />
                                </div>
                                <div className ="div-table-col" style={{width: '35%', height: 'fit-content'}}>
                                    <CheckOuts /> 
                                </div>
                                <div className ="div-table-col" style={{width: '30%', height: 'fit-content'}}>  
                                    <URooms />  
                                </div>
                            </div>
                    </div>
            </div>            
            </ErrorBoundary>
            </div>
        </>
    );
}

export default Dashboard;
