import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { authenticateUser } from './utils/httpUtil';
import { checkError } from './utils/helpers.js';

import "./css/login.css";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authenticated, setAuthentication] = useState(false);

  function checkUserCredentials() {
    const payload = {
      u_name: username,
      pwd: password,
    };
  
    authenticateUser("apiUsers", JSON.stringify(payload))
      .then((response) => checkError(response))
      .then((result) => {
        if (result.length > 0) {
          setAuthentication(true);
          sessionStorage.setItem("authenticated", true);
          sessionStorage.setItem("accessToken", username);
          sessionStorage.setItem("userName", username);
          sessionStorage.setItem("userId", result[0].user_id);
          navigate("/requests", { replace: true });  
        } else {
          setAuthentication(false);
          alert("Incorrect username/password");
        } 
      });
  }

  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="page-header">
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="8">
              <Card className="card-login card-plain" style={{ padding: '2em', backgroundColor: 'saddlebrown' }}>
                <Form className="form">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img style={{ width: 'auto' }} alt="..." src={require("./img/logo.png")}></img>
                      <div style={{ fontSize: '20pt', color: 'white' }}>Reservations Portal</div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={"no-border input-lg" + (firstFocus ? " input-group-focus" : "")}
                    >
                      <Input
                        placeholder="User Name..."
                        type="text"
                        value={username}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={(e) => setUsername(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={"no-border input-lg" + (lastFocus ? " input-group-focus" : "")}
                    >
                      <Input
                        placeholder="Password..."
                        type="password"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={(e) => setPassword(e.target.value)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      style={{ backgroundColor: 'burlywood', border: '1px solid transparent', fontWeight:'bold' }}
                      size="lg"
                      onClick={checkUserCredentials}
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Login;
