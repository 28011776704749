import React, { useState, useEffect } from 'react';

import {logError, checkError} from '../utils/helpers';
import {fetch, store} from '../utils/httpUtil';

import ErrorBoundary from '../ErrorBoundary';
import Header from './Header';

import { useNavigate } from 'react-router-dom'; 

import {Alert, Row, Col} from 'reactstrap';

const References = () => {

    const navigate = useNavigate();

    const [newReference, setNewReference] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();

    const [references, setReferences] = useState([]); 

    useEffect(() => {
        if (sessionStorage.getItem('authenticated') === 'true'){
            fetchReferences();
          } else {
            navigate("/", { replace: true });
          }
    }, []);

    const fetchReferences = async () => {
        fetch("apiReferrals")
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {
            setReferences(result);
        })
        .catch((error) => {
            //notify.show('Oops! Something went wrong! Please try again!', 'error');    
            logError(constructor.name + " " + error);
        });

    };

    const addReference = async () => {

        const payload = {   
            referred_by: newReference  
        };

        store("apiReferrals/1", JSON.stringify(payload))
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {
                var newEntry = {
                    reference_id: references.length + 1,
                    referred_by: newReference   
                };
                setReferences([...references, newEntry]);
                setNewReference('');
            })
            .catch((error) => {
                //notify.show('Oops! Something went wrong! Please try again!', 'error');    
                logError(constructor.name + " " + error);
            });
    };

    return (
        <>
            <Header selected="References"/>
            <div className="divError">    
            <ErrorBoundary>
                <div className="divPageContainer">
                    <h3>References</h3> 
                        <hr />  
                        <Alert color={alertColor} isOpen={showAlert}>
                            {alertMsg}
                        </Alert>

                        <Row>
                        <Col md={4} style={{backgroundColor:'white'}}>

                        <div className="table-container">
                            <table className="simple-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Referral Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {references.map((reference, index) => (
                                        <tr key={reference.reference_id}>
                                            <td>{index + 1}</td>
                                            <td style={{textAlign:'left'}}>{reference.referred_by}</td>
                                        </tr>
                                    ))}
                                    <tr key="new">
                                        <td></td>
                                        <td style={{textAlign:'left'}}>
                                            <input
                                                type="text"
                                                value={newReference}
                                                onChange={(e) => setNewReference(e.target.value)}
                                            />
                                            <button className="btnBig" style={{marginLeft:'2em', backgroundColor:'darkcyan'}} onClick={addReference}>Add</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </Col>
                    </Row>
                </div>
            </ErrorBoundary>
            </div>
        </>
    );
};

export default References;