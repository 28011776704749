import React, { useState, useEffect } from 'react';

import {logError, checkError} from '../utils/helpers';
import {fetch, store, destroy} from '../utils/httpUtil';

import moment from 'moment';    

import "../css/donations.css";   

import ErrorBoundary from '../ErrorBoundary';

import {Alert, Form} from 'reactstrap';

const Donations = ({rId, gId, onSave}) => {


    const [reservationId, setReservationId] = useState(rId);  
    const [guestId, setGuestId] = useState(gId);    

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertMsg, setAlertMsg] = useState();     

    const [newDonation, setNewDonation] = useState({
        donation_id: 0, 
        donation_date: moment().format('YYYY-MM-DD'),
        amount: '',
        receipt_no: '',
        comments: ''
    
    });

    const [donations, setDonations] = useState([{
        donation_id: 0, 
        donation_date: null,
        amount: 0,
        receipt_no: null,
        comments: null
    }]); 

    const fetchDonations = async () => {
        fetch("apiDonation/" + reservationId)
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {

            var donations = result.map((donation) => {

                var pending_donation = donation.expected_donation - donation.received_donation;
                if (pending_donation < 0) {
                    pending_donation = '';
                }

                setNewDonation({...newDonation, amount: pending_donation}); 

                return {
                    donation_id: donation.donation_id, 
                    donation_date: moment(donation.received_on).format('DD-MMM-YY'),
                    amount: donation.amount,
                    receipt_no: donation.receipt_no,
                    comments: donation.comments
                };
            });
            setDonations(donations);         
        })            
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);  
            logError(constructor.name + " " + error);
        });

    };

    useEffect(() => {
        fetchDonations();
    }, []);

    const addDonation = (e) => {

        e.preventDefault();

        var donation = {
            reservation_id: reservationId,
            guest_id: guestId,
            received_on: newDonation.donation_date,
            amount: newDonation.amount,
            receipt_no: newDonation.receipt_no,
            comments: newDonation.comments === null ? '' : newDonation.comments,
            user_id: sessionStorage.getItem('userId')   
        };

        store("apiDonation", donation)
            .then((response) => {
                return checkError(response);
            })
            .then((result) => {
                setAlertMsg("Donation added successfully!");
                setAlertColor("success");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    const updatedTotal = (parseFloat(newTotal) + parseFloat(newDonation.amount)).toLocaleString('en-IN');   
                    onSave(updatedTotal);
                }, 2000);

                var updatedList = [...donations, newDonation];
                setDonations(updatedList);

                setNewDonation({
                    donation_id: 0, 
                    donation_date: moment().format('YYYY-MM-DD'),
                    amount: '',
                    receipt_no: '',
                    comments: ''
                }); 
  
        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
            logError(constructor.name + " " + error);
        });
    };

    const removeDonation = async (donationId, donationAmt) => {  
        destroy("apiDonation/" + donationId)
        .then((response) => {
            return checkError(response);
        })
        .then((result) => {
            var updatedList = donations.filter((donation) => donation.donation_id !== donationId);
            setDonations(updatedList);
            setAlertMsg("Donation removed successfully!");
            setAlertColor("success");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                const updatedTotal = (parseFloat(newTotal) - parseFloat(donationAmt)).toLocaleString('en-IN');   
                onSave(updatedTotal);
            }, 2000);
        })
        .catch((error) => {
            setAlertMsg("Oops! Something went wrong! Please try again!")
            setAlertColor("danger");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);  
            logError(constructor.name + " " + error);
        });
    }  
    
    const formatAsIndianCurrency = (num) => {
        return num.toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
    }

    const handleAmountChange = (e) => { 
        let inputValue = e.target.value;

        // Remove non-numeric characters except for the decimal point
        inputValue = inputValue.replace(/[^0-9.]/g, '');

        // Convert to a number
        const numberValue = parseFloat(inputValue);

        setNewDonation({ ...newDonation, amount: numberValue })
    }

    const newTotal = donations.reduce((total, donation) => total + parseFloat(donation.amount), 0);

      
    return (
            <>

                <div className="divError">    
                <ErrorBoundary>
                    <div className="divPageContainer">
                        {/* <h3>Donations</h3>  */}
                            <hr />  
                            <Form onSubmit={addDonation}>
                            <Alert color={alertColor} isOpen={showAlert}>
                                {alertMsg}
                            </Alert>                            
                                <div className="table-container">                         
                                <table className="simple-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Receipt No.</th>
                                                <th>Comments</th>
                                                <th>Actions</th>    
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {donations.length > 0 && donations.map((donation, index) => (
                                                            <tr key={donation.donation_id} style={{cursor:'auto'}}>
                                                                <td>{index + 1}</td>
                                                                <td>{donation.donation_date}</td>
                                                                <td>&#8377;&nbsp;{donation.amount.toLocaleString('en-IN')}</td>
                                                                <td>{donation.receipt_no}</td>
                                                                <td>{donation.comments}</td>
                                                                <td>
                                                                    <img className="imgIcon" src={require("../img/delete.png")} alt="Remove" onClick={() => removeDonation(donation.donation_id, donation.amount)}></img>
                                                                </td>
                                                            </tr>
                                            ))} 
                                        <tr key="new">  
                                                <td>{donations.length+1}</td>
                                                <td>
                                                    <input
                                                        type="date"
                                                        value={newDonation.donation_date}
                                                        onChange={(e) => setNewDonation({ ...newDonation, donation_date: e.target.value })}
                                                    />
                                                </td>
                                                <td>
                                                    <input required
                                                        type="number"
                                                        value={newDonation.amount}                                                        
                                                        onChange={(e) => handleAmountChange(e)} 
                                                    />
                                                </td>
                                                <td>
                                                    <input required
                                                        type="text"
                                                        value={newDonation.receipt_no}
                                                        onChange={(e) => setNewDonation({ ...newDonation, receipt_no: e.target.value })}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={newDonation.comments}
                                                        onChange={(e) => setNewDonation({ ...newDonation, comments: e.target.value })}
                                                    />
                                                </td>
                                                <td>
                                                    <button type="submit" style={{ border: 'none', background: 'none', padding: 0 }}>
                                                        <img className="imgIcon" src={require("../img/add.png")} alt="Add" />
                                                    </button>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    Total:                                                    
                                                </td>
                                                <td colSpan="5">
                                                    <b>&#8377;&nbsp;{newTotal.toLocaleString('en-IN')}</b>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                             </div>
                            </Form>
                        </div>  
                    </ErrorBoundary>
                </div>
            </>
        );
};
                                
export default Donations;