import {React, useState, useEffect} from "react";

import { blocks } from '../constants/roomAttributes.js';

import {fetch} from '../utils/httpUtil.js';   

import {checkError, logError} from '../utils/helpers.js';   

import { useNavigate } from "react-router-dom";


function TodayAvailability () {

  const navigate = useNavigate();

  const [availabilityItems, setAvailabilityItems] = useState([]);

  useEffect(() => {
    fetchAvailableRooms();
  }, []); 

  function fetchAvailableRooms(){
      fetch("apiArooms/1")
      .then((response) => {
      return checkError(response);
      })
      .then((result) => {
        setAvailabilityItems(result); 
        })
      .catch((error) => {
        logError(this.constructor.name + " " + error);
      });
  }

  function addWalkIn(){
      navigate("/walkins", { replace: true }  )
  }

    if (availabilityItems.length == 0){
          return  (
          <div><h4>Available Rooms (Unbooked)</h4><hr /> No rooms! </div>
          );
    } else {
          return (
            <div><h4>Available Rooms (Unbooked)</h4><hr />
            <div className="div-table-today">
             <ul>
                 {availabilityItems.map(item => (
                  <li key={item.block_id} style={{ visibility: item.block_id !== (10, 13) ? 'visible':'hidden', display: item.block_id !== (10, 13)? 'inline':'none' }}>
                    <span className="spCount" style={{color: item.block_id === 11? 'red': ''}}>{item.count}</span><br/>
                    <span className="spNameCount"> {blocks[item.block_id]} </span>
                  </li>
                ))}
             </ul>
             </div>
              <button className="btnBig" style={{marginTop:'0', marginLeft:'2em', backgroundColor:'darkcyan', float:'right'}} onClick = {() => {addWalkIn()}}>Quick Check In</button> 
             </div>
          );
    }

  }

export default TodayAvailability;