import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import moment from 'moment';

import {fetch} from '../utils/httpUtil.js';
import {checkError, logError} from '../utils/helpers.js';
import {reservationTypes, sanskaras} from '../constants/roomAttributes.js'; 

import {Alert} from 'reactstrap';

function AllRequests() {

  const navigate = useNavigate();

  const [requestItems, setRequestItems] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState();
  const [alertMsg, setAlertMsg] = useState();

  useEffect(() => {
    if (sessionStorage.getItem('authenticated') === 'true'){
      fetchAllRequests();
    } else {
      navigate("/", { replace: true });
    }

    function fetchAllRequests() {
      return fetch("apiRequests/2")
      .then((response) => {
          return checkError(response);
      })
      .then((result) => {
        setRequestItems(result); 
      })
      .catch((error) => {
        setAlertMsg("Oops! Something went wrong! Please try again!")
        setAlertColor("danger");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000); 
        logError(error);
      });
    }
  }, []);



  function showProcessReservationRequest(rTypeID, rID, rStatusID, gID, guestName, emailID, phoneNo, dateArrival, dateDeparture, noOfPeople, sanskaraID) { 

    const reservationItem = {
      rTypeId: rTypeID, 
      rStatusId: rStatusID, 
      rId: rID, 
      gId: gID, 
      guestName: guestName, 
      emailId: emailID, 
      phoneNo: phoneNo,
      dateArrival: dateArrival, 
      dateDeparture: dateDeparture,
      noOfPpl: noOfPeople,
      sanskaraId: sanskaraID 
    }

    navigate("/reservationContainer", { state: {reservationItem } });

  }

  const handleRowClick = (e, item) => {
    // Check if the click is coming from email or phone element
    if (e.target.classList.contains('contact-info') || e.target.closest('.contact-info')) {
      return;
    }
    showProcessReservationRequest(item.reservation_type_id, item.reservation_id, item.reservation_status_id, item.guest_id, item.guest_name, item.email_id, item.phone_no, 
      item.date_of_arrival, item.date_of_departure, item.no_of_people, item.sanskara_id);
  };

  if (requestItems.length === 0) {
      return (
        <>
          <div className="divSectionHeader"><h4>All Requests</h4>
          <hr />  
          No requests!
          </div>  
        </>
        );
  }
  else {
      return (
        <>
          <div className="divSectionHeader">
            <h4>All Requests</h4>
                <hr />  
                <Alert color={alertColor} isOpen={showAlert}>
                    {alertMsg}
                </Alert>
                <div className="table-container">
                <table className="simple-table">
                      <thead>
                          <tr>
                              <th style={{width: '15%'}}>Reservation Type</th>
                              <th style={{width: '25%'}}>Guest Name</th>
                              <th style={{width: '20%'}}>Requested Dates</th>
                              <th style={{width: '10%'}}>Pax</th>
                              <th style={{width: '15%'}}>Requested On</th>
                          </tr>
                      </thead>
                      <tbody>
                          {requestItems.map(item => {
                                const typeClass = reservationTypes[item.reservation_type_id].toLowerCase();
                                return (

                                <tr key={Math.random()} onClick={(e) => handleRowClick(e, item)} style={{backgroundColor: parseInt(item.no_of_people) >= 10 ? 'lemonchiffon' : null}}>
                                    <td style={{width: '15%'}}><span className={`reservation-item ${typeClass}`}>{reservationTypes[item.reservation_type_id]}{item.sanskara_id === null ? null : ' - ' + sanskaras[item.sanskara_id]}</span></td>
                                    <td style={{ width: '25%' }}>
                                        {item.guest_name}
                                        <br />
                                        <div className="contact-info" style={{fontSize:'9pt'}}>
                                          {item.email_id ? (
                                            <>
                                              {item.email_id}
                                              {item.phone_no && (
                                                <>
                                                  &nbsp;|&nbsp;
                                                  {item.phone_no}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            item.phone_no && (
                                              <>
                                                {item.phone_no}
                                              </>
                                            )
                                          )}
                                        </div>
                                    </td>
                                    <td style={{width: '20%'}}>{moment(item.date_of_arrival).format('DD-MMM-YY')} - {moment(item.date_of_departure).format('DD-MMM-YY')}</td>
                                    <td style={{width: '10%'}}>{item.no_of_people}</td>
                                    <td style={{width: '15%'}}>{item.created_on}
                                      </td> 
                                </tr>
                                );  
                          })} 
                      </tbody>
                  </table>
                </div>
            </div>
        </>
      );
  }
}

export default AllRequests;
